import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils'
import { toast } from 'react-toastify'
import { Attachment,Attributes } from './types';
// @ts-ignore
import * as configData from "../../../framework/src/config";
import { getStorageData } from '../../../framework/src/Utilities';

interface SurveyQuestions {
  type: string;
  id: string;
  attributes: {
    question_type: string;
    question_condition_type: null | string;
    response_type: string;
    sequence_no: number;
    question: string;
    parent_expected_answer: any[];
    parent: null | {
      question: string;
      survey_id: number;
      response_type: string;
      question_type: string;
      updated_at: string;
      created_at: string;
      sequence_no: number;
      parent_expected_answer: any[];
      question_condition_type: null | string;
      id: string;
      parent_id: null;
    };
    response_type_id: number;
    options: {
      title: string;
      id: number;
      is_deleted: boolean;
    }[];
    question_attachments: null;
    childQue?: SurveyQuestions[];
    answers: any[];
  };
}

interface Community
  {
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      description: string,
      community_type: string,
      account_id: number
      is_admin: boolean,
      post_count: number
      preferences: [],
      preference_ids: [],
      categories: [],
      category_ids: [],
      Sub_categories: [],
      Sub_category_ids: [],
      moderator_ids: [],
      member_ids: Array<number>
    }
  }

  interface TaggedUser {
    id: string,
    type: string,
    attributes: {
      id: number,
      first_name: string,
      last_name: string,
      email: string,
      qb_id: null,
      profile_pic: string
  }}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    questions: any[];
    surveyName: string;
    surveyFilled: boolean;
    surveyDrafted:boolean;
    anchorElForPopup: HTMLButtonElement | null;
    sumValue: number;
    sumArray: any[];
    surveyidArray: any[];
    loading: boolean;
    isdisabled: boolean;
    scorringSurveyFilled: boolean;
    scorringSurveyDrafted:boolean;
    // allQuestion: any;
    resultQueData:any;
    surveyData: any;
    clearTimer: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class CreateSurveysController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    enduserSurveyId: string = "";
    downloadReportCallId = '';
    responseSubmitCallId = '';
    responseSubmitScoringCallId='';
    getAllScoringQuestionId='';
    getServeyDetailCallId='';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
      
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: '',
            questions: [],
            surveyName: '',
            surveyFilled: false,
            surveyDrafted:false,
            anchorElForPopup: null,
            sumValue: 0,
            sumArray: [],
            surveyidArray: [],
            loading:true,
            isdisabled: false,
            scorringSurveyFilled:false,
            scorringSurveyDrafted:false,
            // allQuestion: [],
            surveyData: [],
            resultQueData: [],
            clearTimer: null
            
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const authToken = await getStorageData("authToken");
        if (authToken == null) {
          this.props.navigation.navigate('Home')
        } else {
          this.setState({ token: authToken }, () => {
            const access = this.props?.navigation?.history?.location?.state?.ability_type
            access == "read" && this.setState({ isdisabled: true })
            this.getSurveyDetails()
            this.getSurveyQuestion();
          });
        }
    }

    // Customizable Area End
    async receive(from: string, message: Message) {

        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const apiRequestCallIds = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (responseJson && (responseJson.errors || responseJson.error)) {
                this.parseApiErrorResponse(responseJson.errors);
                this.parseApiCatchErrorResponse(responseJson.errors);
                this.setState({ loading: false });
                Array.isArray(responseJson.errors) ? toast.error(responseJson.errors[0]) : toast.error(responseJson.error) ;
            } else if (responseJson) {
                switch (apiRequestCallIds) {
                    case this.enduserSurveyId:
                      this.handleEndUserSurveyResponse(responseJson);
                      break;
                  
                    case this.getAllScoringQuestionId:
                      this.handleAllScoringQuestionResponse(responseJson);
                      break;
                  
                    case this.downloadReportCallId:
                      this.handleDownloadReportResponse(responseJson);
                      break;
                  
                    case this.responseSubmitCallId:
                      this.handleResponseSubmitResponse(responseJson);
                      break;
                  
                    case this.responseSubmitScoringCallId:
                      this.handleResponseSubmitScoringResponse(responseJson);
                      break;
                    case this.getServeyDetailCallId:
                    const data = responseJson?.data?.attributes?.tagged_users.data;
                    const created_by = responseJson?.data.attributes.created_by;
                    const communityData = responseJson?.data?.attributes?.tagged_communities?.data;
                    const status = responseJson?.data?.attributes?.status;
                    this.handleGetSurveyDetailApiCall(data,communityData,status,created_by)
                      break;
                    
                    default:
                      break;
                  }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

  handleGetSurveyDetailApiCall = (data: Array<TaggedUser>, communityData: Array<Community>, status: string, created_by: { [key: string]: string }) => {
    const user: string = localStorage.getItem('user') ?? '';

    if (JSON.parse(user).id !== created_by.id.toString()) {
      if (Array.isArray(data) && data.length > 0 || communityData.length > 0) {
      const matchedUser = data.some((item: TaggedUser) => {
          return item.attributes.email === JSON.parse(user).email;
        });
        const matchedCommunity = communityData.some((item: Community) =>
          item.attributes.member_ids.includes(Number(JSON.parse(user).id))
        );
        if (matchedUser || matchedCommunity) {
          !['live', 'inactive'].includes(status) && this.navigateActivityFeedPage()
        }
        else {
          this.navigateToActivityFeedPage();
        }
      }
      else {
        this.navigateToActivityFeedPage();
      }


    }
  };
    
    isEmpty = (str: any) => (!str?.length);

    hideModal = () => { this.props.navigation.goBack(); }

    getSurveyDetails = () => {
      const survey_id = this.props.navigation.getParam('id');
      const { token } = this.state
      this.getServeyDetailCallId = sendAPIRequest(configJSON.getSurveyByIdEndpoint.replace(':sid', survey_id), {
        method: 'GET',
        headers: { token }
      })
  }

    getSurveyQuestion = () => {
        const id = this.props.navigation.getParam('id');
        const { token } = this.state
        this.enduserSurveyId = sendAPIRequest(
            `${configJSON.endUserQuestion}/${id}/survey_questions`,
            { method: 'GET', headers: { token } }
        )
    }
    getAllScoringQuestion = () => {
      const survey_id = this.props.navigation.getParam('id')
      const { token } = this.state
        this.getAllScoringQuestionId = sendAPIRequest(configJSON.getScoringQuestionByIdEndpoint.replace(':sid', survey_id), {
          method: 'GET',
          headers: {token},
        })
      }

    allowOnlyNumbers = (event:any) => {
        if (event.key.length === 1 && /\D/.test(event.key)) {
          event.preventDefault();
        }
      };

    handleSubjectiveChange = (event:any, values:any, id: any, parentIndex?:number) => {
        values.question_answers_attributes.map((data:any)=> {
            if(data.survey_question_id == id){
                data.answer = event.target.value
            }
        })        
    }

  handleMultipleSelect = (event: any, values: any, id: any, index: any, questionType: number,isDrafted:boolean, parentIndex?: number) => {
    let optionId = 0;
    this.state.resultQueData[index]?.attributes.options.map((data: any) => {
        if (event.target.value == data.title) {
          optionId = data.id
        }
    })
    this.expectedChildrenData(optionId, id)

    if (parentIndex == 0 || parentIndex != undefined) {
      // setting child option 
      const parentQuestion = this.state.resultQueData[parentIndex].attributes.nested_child[index];
      
      const selectedOption = parentQuestion.attributes.options.find((option: any) => option.title === event.target.value);
      if (selectedOption) {
          optionId = selectedOption.id;
      }
    }

    values.question_answers_attributes.forEach((data: any) => {

      if (data.survey_question_id == id && data.responseTypeId === questionType) {
        data.option_id = optionId
        data.answer = event.target.value
      }

    })
  }

  expectedChildrenData = (optionId: number, id: number) => {
    this.setState(prevState => {
      const setSurveyData = [...prevState.surveyData];
      let response = { updatedValues: setSurveyData, linkedQuesAns: [] }
      const updatedResultQueData = prevState.resultQueData.map((question: any, questionIndex: number) => {
        if (question.attributes.children.length > 0 && question.id == id) {

          // setting response_type for scoring
          question.attributes.children?.map((child: any) => {
            if (child && child?.data.attributes.response_type_id !== 0 && !child?.data.attributes.response_type_id) {
              child.data.attributes["response_type"] = "scoring";
              child.data.attributes["response_type_id"] = 4;
            }
          });
          
          response = this.updateSurveyRes(question, id, setSurveyData, optionId)

          return {
            ...question,
            attributes: {
              ...question.attributes,
              nested_child: response?.linkedQuesAns
            },
          };
        }
        return question;
      });
      return {
        resultQueData: updatedResultQueData,
        surveyData: response?.updatedValues
      };
    });
  }

  isValidSortChild = (data: any) => {
    if(data.length > 0){
    let updatedData = this.updateDataWithHost(data);
    return updatedData.slice().sort((a: any, b: any) => a.attributes.sequence_no - b.attributes.sequence_no)
    }
    return []
  }

  getFilterChildrenData = (data: any, optionId: number) => {
    let resultData = data.filter((child: any) => {
      let parentExpectedAnswer
      if(Array.isArray(child.data.attributes.parent_expected_answer[0]) ){
        let data = child.data.attributes.parent_expected_answer[0][0];
        parentExpectedAnswer = JSON.parse(data);
      }else{
        parentExpectedAnswer = JSON.parse(child.data.attributes.parent_expected_answer[0]);
      }
      if (Array.isArray(parentExpectedAnswer)) {
        return parentExpectedAnswer?.some(obj => obj.id === optionId);
      } else {
        return parentExpectedAnswer?.id === optionId;
      }
    });
    return resultData
  }

  handleSummationChange = (event: any, values: any, id: any, quesId: any, index: any, parentIndex?: number) => {
    let totalSum = 0;
    let answersDataLength = 0;
    values.question_answers_attributes.forEach((item: any) => {
      if (Array.isArray(item)) {
        item.forEach(element => {
          if (element.option_id === id && element.survey_question_id === quesId) {
            element.answer = event.target.value;
            totalSum = item.reduce((acc: number, child: any) => acc + Number(child.answer), 0);
            answersDataLength = item.filter((answer: any) => answer.answer !== null && answer.answer !== '').map((answer: any) => Number(answer.answer)).length
          }
        });
      }
    });

    let isChild = parentIndex == undefined ? true : false

    if (this.state.clearTimer) {
      clearTimeout(this.state.clearTimer);
    }
    let clearTimer = setTimeout(() => {
      if (isChild) {
        this.expectedChildrenSummationData(quesId, totalSum,answersDataLength);
      }
    }, 500);

    this.setState({
      clearTimer
    })
  }

    
  getFilterChildSummationData = (data: any, totalSum: number) => {
    let resultData: any[] = [];
    data.forEach((question: any) => {
      const parentExpectedAnswer = question && JSON.parse(question.data.attributes.parent_expected_answer[0]);
      if (Array.isArray(parentExpectedAnswer) && parentExpectedAnswer.length === 2) {
        const lessThan = parentExpectedAnswer.find((obj: any) => obj.id === 1);
        const greaterThan = parentExpectedAnswer.find((obj: any) => obj.id === 2);
        if (lessThan && greaterThan && totalSum < lessThan.value && totalSum > greaterThan.value) {
          resultData.push(question);
        }
      } else {
        parentExpectedAnswer?.forEach((obj: any) => {
          if ((obj.id === 1 && totalSum < obj.value) || (obj.id === 2 && totalSum > obj.value)) {
            resultData.push(question);
          }
        });
      }
    });
    return resultData;
  }
  getChildIds = (quesId: any) => {
    const childrenIds: any = [];
    this.state.resultQueData.forEach((question: any) => {
      if (question.id == quesId && question.attributes.children && question.attributes.children.length > 0) {
        question.attributes.children.forEach((child: any) => {
          childrenIds.push(child?.data.id);
        });
      }
    });
    return childrenIds
  }

  storePreviousChild = (currentNestedChildId: any, setSurveyData: any) => {
    let hasMatchingIds: any = []
    if (this.isEmptyArray(currentNestedChildId)) {
      hasMatchingIds = setSurveyData.filter((item: any) => {
        if (Array.isArray(item)) {
          return item.some((obj: any) => currentNestedChildId.includes(obj.survey_question_id));
        } else {
          return currentNestedChildId.includes(item.survey_question_id);
        }
      });
    }
    return hasMatchingIds;
  };

  removePreviousChild = (childId: any, setSurveyData: any) => {
    let filterArray: any = [];
    // If matching IDs don't exist, proceed with removal logic
    setSurveyData.forEach((item: any) => {
      if (Array.isArray(item)) {
        if (!childId.includes(item[0].survey_question_id)) {
          filterArray.push(item);
        }
      } else {
        if (!childId.includes(item.survey_question_id)) {
          filterArray.push(item);
        }
      }
    });
    return filterArray;
  };

  expectedChildrenSummationData = ( id: number, totalSum: number,answersDataLength:number) => {
    this.setState(prevState => {
      const setSurveyData = [...prevState.surveyData];
      let response = { updatedValues: setSurveyData, linkedQusAns: [] }
      const updatedSummationData = prevState.resultQueData.map((question: any) => {

        if (question.attributes.children.length > 0 && question.id == id) {
          // setting response_type for scoring
          question.attributes.children.map((childData: any) => {
            if (childData && childData?.data.attributes.response_type_id !== 0 && !childData?.data.attributes.response_type_id) {
              childData.data.attributes["response_type_id"] = 4;
              childData.data.attributes["response_type"] = "scoring";
            }
          });

          response = this.updateSurveyData(question, id, setSurveyData, totalSum,answersDataLength)
          return {
            ...question,
            attributes: {
              ...question.attributes,
              nested_child: response?.linkedQusAns
            },
          };
        }
        return question;
      });
      return {
        resultQueData: updatedSummationData,
        surveyData: response?.updatedValues
      };
    });
  }

  updateSurveyData = (question: any, id: number, setSurveyData: any, totalSum: number,answersDataLength:number) => {
    let updatedValues: any = setSurveyData
    const linkedQusAns: any = [];
    let childId = this.getChildIds(id)
    const filteredChildData = answersDataLength == 0 ? [] : this.getFilterChildSummationData(question.attributes.children, totalSum)
    const currentNestedChildId = filteredChildData.map((e: any) => e.data.id)
    const nestedChildId = question.attributes?.nested_child?.map((e: any) => e.id)
    if (this.isEmptyArray(childId)) {
      updatedValues = this.removePreviousChild(childId, setSurveyData)
    }
    
    // replace existing updatedValues when option change, no need to update
    this.storePreviousMatchID(currentNestedChildId, setSurveyData, updatedValues)

    filteredChildData.forEach((childQues: any) => {
      linkedQusAns.push(childQues.data);
      if (!nestedChildId?.includes(childQues.data.id)) {
        // Setting initial value for child
        if (this.getQuestionAnswersAttributes(childQues.data)) {
          updatedValues.push(this.getQuestionAnswersAttributes(childQues.data));
        }
      }
    })

    let linkedQusAnsSort = this.isValidSortChild(linkedQusAns)
    return { updatedValues: updatedValues, linkedQusAns: linkedQusAnsSort }
  }


  updateSurveyRes= (question: any, id: number, setSurveyData: any, optionId: number) => {
    let updatedValues: any = setSurveyData
    const linkedQuesAns: any = [];
    let childId = this.getChildIds(id)
    const filteredChildData = this.getFilterChildrenData(question.attributes.children, optionId)
    const currentNestedChildId = filteredChildData.map((e: any) => e.data.id)
    const nestedChildId = question.attributes?.nested_child?.map((e: any) => e.id)
    if (this.isEmptyArray(childId)) {
      updatedValues = this.removePreviousChild(childId, setSurveyData)
    }
    
    // replace existing updatedValues when option change, no need to update
    this.storePreviousMatchID(currentNestedChildId, setSurveyData, updatedValues)

    filteredChildData.forEach((childQues: any) => {
      linkedQuesAns.push(childQues.data);
      if (!nestedChildId?.includes(childQues.data.id)) {
        // Setting initial value for child
        if (this.getQuestionAnswersAttributes(childQues.data)) {
          updatedValues.push(this.getQuestionAnswersAttributes(childQues.data));
        }
      }
    })

    let linkedQuesAnsSort = this.isValidSortChild(linkedQuesAns)
    return { updatedValues: updatedValues, linkedQuesAns: linkedQuesAnsSort }
  }

  storePreviousMatchID = (currentNestedChildId: any, setSurveyData: any, updatedValues: any) => {
    let hasMatchingIdArray: any = []
    if (this.isEmptyArray(currentNestedChildId)) {
      hasMatchingIdArray = this.storePreviousChild(currentNestedChildId, setSurveyData)
      hasMatchingIdArray?.forEach((childObj: any) => {
        if (Array.isArray(childObj)) {
          if (currentNestedChildId.includes(childObj[0].survey_question_id)) {
            updatedValues.push(childObj);
          }
        } else {
          if (currentNestedChildId.includes(childObj.survey_question_id)) {
            updatedValues.push(childObj);
          }
        }
      })
    }
  }
  
    arr:any[] = [];
    sum:any = []
    total = 0
    checkindex = 0
    finalArr = []
    handleBlur = (value:any, index:number, id: any, no:any) => {
        if(no !== this.checkindex) this.arr = []
        if(this.arr[index]?.id == id){
            this.arr[index].value = value
        }
        else{
            this.arr.push({id: id, value: value})
        }
        console.log("arr=>", this.arr);
        this.total = this.arr.reduce((sum: any, acc: any)=> { 
            return sum = Number(sum) + Number(acc.value)
        },0)
        this.sum[no] = this.total
        // this.setState({sumArray: this.sum})
        console.log("total is=> ",this.arr,  this.total, this.state.sumArray, this.checkindex, no)
        this.checkindex = no
    }
    
    handleResponseSubmit = async(values:any,setSubmitting:any,isDrafted:boolean = false) => {
      console.log(values.question_answers_attributes,"values handleResponseSubmit****")
        // Change response before sumbit for summation
        let refactorAnswer = []
        refactorAnswer = values.question_answers_attributes?.flatMap((element: any) => {
          if (Array.isArray(element)) {
            return element.map(subElement => ({ ...subElement }));
          } else {
            return element;
          }
        });

        const tempId = this?.props?.navigation?.getParam('id');
        const scoringArray = values.question_answers_attributes.filter((obj:any) => obj.responseTypeId === 4);
        const otherArray = refactorAnswer.filter((obj:any) => obj.responseTypeId !== 4);
        

        const  otherResult = {
          draft:isDrafted,
          survey_id: tempId,
          question_answers_attributes: otherArray.map((obj:any) => ({
            survey_question_id: obj.survey_question_id,
            question_type: obj.question_type,
            option_id: obj.option_id,
            answer: obj.answer
          }))
        };
        const scoringResult = {
            draft:isDrafted,
            survey_id: tempId,
            scoring_question_answers_attributes:scoringArray.map((obj:any) => ({
            scoring_survey_question_id: obj.survey_question_id,
            scoring_option_id: obj.option_id,
            answer: obj.answer,
            question_type: obj.question_type,
            }))
        };   
       
        const isValidQue = await this.areQuestionsValid(refactorAnswer);
        if(isValidQue || isDrafted){
          const { token } = this.state;
          this.setState({ loading: false })

          if (Array.isArray(otherArray) && otherArray.length) {
            this.responseSubmitCallId = sendAPIRequest(`${configJSON.endUserQuestion}/${tempId}/survey_responses`,
              {
                method: 'POST',
                headers: { token, 'Content-Type': 'application/json', },
                body: otherResult,
              }
            )
          }

          if (Array.isArray(scoringArray) && scoringArray.length > 0) {
            this.responseSubmitScoringCallId = sendAPIRequest(`${configJSON.endUserScoringQuestion}/${tempId}/survey_responses`,
              {
                method: 'POST',
                headers: { token, 'Content-Type': 'application/json', },
                body: scoringResult,
              }
            )
          } 
        }
        else{
            this.setState({loading: false})
            toast.error("Please Answer all mandatory questions")
        }
    }
 
    isQuestionValid = async (question: any): Promise<boolean> => {
      if (question.question_type === "mandatory") {
      if ("answer" in question) {
        if (question.answer !== null && question.answer.trim() !== "") {
          return true; // Valid if answer is provided and not empty
        }
      } else if (question.option_id !== undefined && question.option_id !== null) {
        return true; // Valid if option_id is provided
      }
      return false; // Invalid if both answer and option_id are missing
    }
    return true; // Not mandatory questions are considered valid
  }

   areQuestionsValid = async (questions: any[]): Promise<boolean> => {
    for (const question of questions) {
      const isValid = await this.isQuestionValid(question);
      if (!isValid) {
        return false; // If any question is invalid, return false
      }
    }
    return true; // All questions are valid
  }

    handleCancel = () => {
        this.props.navigation.goBack();
    }

    onBack = () => {
        this.props.navigation.goBack();
    }

    onHandleDownload = (id: any, fileType: any) => {
        const {token} = this.state;
        this.downloadReportCallId = sendAPIRequest((fileType === "pdf")?configJSON.downloadSurveyPdfReport.replace(':id', id) : configJSON.downloadSurveyExcelReport.replace(':id', id),{
          method: 'GET',
          headers: {token}
        })
        this.setState({...this.state, anchorElForPopup: null})
      }
      updateDataWithHost = (data:any) => {
        const host = configData.baseURL
        return data.map((obj:any) => {
          const questionAttachments = obj?.attributes?.question_attachments?.map(
            (attachment:Attachment) => ({
              ...attachment,
              url: host + attachment.url,
            })
          );
      
          return {
            ...obj,
            attributes: {
              ...obj.attributes,
              question_attachments: questionAttachments,
            },
          };
        });
      };

    handleEndUserSurveyResponse = async(responseJson: any) => {
        let questions = await this.updateDataWithHost(responseJson?.questions?.data);
        this.setState({
          surveyName: responseJson?.survey_template_name,
          surveyFilled: responseJson?.response_filled,
          surveyDrafted: responseJson?.draft,
          questions: questions,
          loading: false
        }, () => {
          this.getAllScoringQuestion()
        });
      }
      
    handleAllScoringQuestionResponse = async(responseJson: any) => {
        const allScoringQuestion = { ...responseJson?.questions };
        const scoringQuestionsData = allScoringQuestion?.data?.map((question: any) => {
          const queObj = question.attributes;
          queObj["response_type"] = "scoring";
          queObj["response_type_id"] = 4;
          return question;
        });
        let scoringQuestions = await this.updateDataWithHost(scoringQuestionsData);
      
        const checkSequence = scoringQuestions?.every((obj: any) => typeof obj.sequence_no === 'number');
        const allQuestions = checkSequence ? scoringQuestions?.sort((a: any, b: any) => a.sequence_no - b.sequence_no) : scoringQuestions?.sort((a: any, b: any) => a.id - b.id);
        const questionsData = this.state.questions?.concat(allQuestions);
        const isValidSequence = questionsData.every((obj) => typeof obj.attributes.sequence_no === 'number');
        const validSorting = questionsData.slice().sort((a, b) => a.attributes.sequence_no - b.attributes.sequence_no);
        const sortedData = questionsData.slice().sort((a, b) => a.id.localeCompare(b.id));
        const sortedQuestions = isValidSequence ? validSorting : sortedData;
        const resultData = this.addChildQuestion(sortedQuestions);
       console.log("result+++",resultData);
       
        if (Array.isArray(scoringQuestionsData) && scoringQuestionsData.length) {
          this.setState({
            resultQueData: resultData,
            questions: sortedQuestions,
            loading: false,
            surveyName: responseJson?.survey_template_name,
            scorringSurveyFilled: responseJson?.response_filled,
            scorringSurveyDrafted: responseJson?.draft,
          });
        } else {
          this.setState({
            resultQueData:resultData,
            questions: sortedQuestions,
            loading: false,
            surveyName: responseJson?.survey_template_name
          });
        }
        let newQueastiondata = this.checkParentAnswers(sortedQuestions)
        this.setInitialStateForForm(newQueastiondata)
      }
      checkParentAnswers(data:any) {
        const newData = [];
        for (const currentQuestion of data) {      
          // Check if the question has a parent
          if (currentQuestion.attributes.parent_id > 0) {
            const parentId = currentQuestion.attributes.parent_id;
      
            // Find the parent question from the array based on id
            const parentQuestion = data.find((q:any) => q.id == parentId);
            
      
              // Use answers from the parent question
           const question =    parentQuestion && this.isQuestionAllowed(currentQuestion,parentQuestion)
           if(question) {
            newData.push(currentQuestion )
 }
          }
          else {
            newData.push(currentQuestion); 
          }
        }
        return newData;
      }
      isQuestionAllowed = (currentQuestion:any, parentQuestion:any) => {
        const parentAnswers = parentQuestion.attributes.answers.map((answer:any) => answer.answer);
        const parentAnswersData = parentQuestion.attributes.answers.map((answer:any) => answer.scoring_option_id).filter((answer:any) => answer !== null);
        const parentQuestionType = parentQuestion.attributes.response_type_id
        let parentExpectedAnswer
        if(Array.isArray(currentQuestion.attributes.parent_expected_answer[0]) ){
          let data = currentQuestion.attributes.parent_expected_answer[0][0];
          parentExpectedAnswer = JSON.parse(data);
        }else{
          parentExpectedAnswer = JSON.parse(currentQuestion.attributes.parent_expected_answer[0]);
        }
        switch (parentQuestionType) {
          case 1:
            if (this.isType1Allowed(parentAnswers, parentExpectedAnswer)) {
              return true;
            }
            break;
          case 4:
            if (this.isType4Allowed(parentAnswersData, parentExpectedAnswer)) {
              return true;
            }
            break;
          case 2:
            if (this.isType2Allowed(parentQuestion, currentQuestion)) {
              return true;
            }
            break;
          default:
            return false;
        }

      }
       isType1Allowed = (parentAnswers:any, parentExpectedAnswer:any) => {
        const currentAnswer = parentExpectedAnswer.title;
        return parentAnswers.includes(currentAnswer);
      }
    
       isType4Allowed = (parentAnswersData:any, parentExpectedAnswer:any) =>  {
        let parentAnswer = parentAnswersData[parentAnswersData.length - 1]
        return parentExpectedAnswer.some((expectedAnswer:any) =>
            expectedAnswer.id === parentAnswer
        );
      }
    
       isType2Allowed = (parentQuestion:any, currentQuestion:any) => {
        const parentAnswers = parentQuestion.attributes.answers
        .filter((answer:any) => answer.answer !== null && answer.answer !== "") // Remove null answers
        .map((answer:any) => Number(answer.answer))

        if (parentAnswers.length == 0) {
          return false
        }  
    
        // Parse the parent_expected_answer as JSON from the current question
        const parentExpectedAnswers = JSON.parse(currentQuestion.attributes.parent_expected_answer[0]);
    
        // Sum up the values in the answer field
        const totalAnswerValue = parentAnswers.reduce((sum:any, value:any) => sum + value, 0);
    
        // Check based on the presence of ID 1 and ID 2 in parentExpectedAnswers
        const isLessThanCondition = parentExpectedAnswers.some((expectedAnswer:any) => expectedAnswer.id == 1);
        const isGreaterThanCondition = parentExpectedAnswers.some((expectedAnswer:any) => expectedAnswer.id == 2);
    
        // Apply conditions
        return (
          (!isLessThanCondition || totalAnswerValue < parentExpectedAnswers.find((a:any) => a.id === 1).value) &&
          (!isGreaterThanCondition || totalAnswerValue > parentExpectedAnswers.find((a:any) => a.id === 2).value)
        );
      }
    handleDownloadReportResponse(responseJson: any) {
        if (responseJson?.surveys?.data?.attributes?.download_url == null) {
          toast.warn("Report is not generated");
        } else {
          window.open(responseJson?.surveys?.data?.attributes?.download_url);
        }
      }
      
    handleResponseSubmitResponse(responseJson: any) {
        const scoringQuestionExists = this.state.questions.some(question => question.attributes.response_type === "scoring");
        if (!scoringQuestionExists) {
          if (responseJson?.message == "Response saved!") {
            toast.success("Response Submitted");
            this.getSurveyQuestion();
            setTimeout(() => {
              this.props.navigation.goBack();
            }, 1500);
          }
        }
      }

    handleResponseSubmitScoringResponse(responseJson: any) {
        if (responseJson?.message == "Response saved!") {
          toast.success("Response Submitted");
          this.getSurveyQuestion();
          setTimeout(() => {
            this.props.navigation.goBack();
          }, 1500);
        } else {
          toast.error(responseJson?.message);
        }
      }
      totalSumAnswers(attributes:Attributes) {
        if (!attributes || !Array.isArray(attributes.answers)) {
          return 0;
        }
        else {
          const sum = attributes.answers.reduce((acc, { answer }) => {
            const parsedAnswer = Number(answer);
            return Number.isNaN(parsedAnswer) ? acc : acc + parsedAnswer;
          }, 0);
          return sum;
        }
      }
      handleQuestionString(input:string) {
        const questionLabel = input !== undefined ? input : 'Scoring'
        const questionText =  questionLabel.replace(/_/g, ' ').replace(/\b\w/g, match => match.toUpperCase())
        return questionText
      }

      calculateAllreadySubmitted(scoringQuestionExists:boolean, otherQuestionExists:boolean, checkfilled:boolean, checkScoringfilled:boolean) {
        let allreadySubmitted = false;
      
        if (scoringQuestionExists && otherQuestionExists) {
          allreadySubmitted = checkfilled && checkScoringfilled;
        } else if (scoringQuestionExists) {
          allreadySubmitted = checkScoringfilled;
        } else {
          allreadySubmitted = checkfilled;
        }
      
        return allreadySubmitted;
      }

  getIndexLabel = (index: number,parentIndex?: number ): string => {
    if(parentIndex != undefined && parentIndex != null){
      return `${parentIndex + 1}.${index + 1} `;
    }
    return `${index + 1}. `;
  };

  addChildQuestion(childData: SurveyQuestions[]): SurveyQuestions[] {
    const questionMapping: Record<string, SurveyQuestions> = {};

    childData.forEach((questions) => {
      questionMapping[questions.id] = questions;
    });

    childData.forEach((questions) => {
      if (questions.attributes.parent) {
        const parentIdForChild = questions.attributes.parent.id;
        if (questionMapping[parentIdForChild]) {
          if (!questionMapping[parentIdForChild].attributes.childQue) {
            questionMapping[parentIdForChild].attributes.childQue = [];
          }

          const childQuestions = { ...questions };
          
          const index = questionMapping[parentIdForChild].attributes.childQue!.findIndex(
            (child) => child?.id === childQuestions.id
            );
            console.log("childQuestions++++",childQuestions,questionMapping[parentIdForChild],index);

          if (index === -1) {
            questionMapping[parentIdForChild].attributes.childQue!.push(childQuestions);
          }
        }
      }
    });

    Object.values(questionMapping).forEach((question) => {
      if (question.attributes.childQue) {
        const checkSequenceQuestionData = question.attributes.childQue.every(
          (obj) => typeof obj.attributes.sequence_no === 'number'
        );

        question.attributes.childQue.sort((a, b) =>
          checkSequenceQuestionData
            ? a.attributes.sequence_no - b.attributes.sequence_no
            : a.id.localeCompare(b.id)
        );
      }
    });

    const resultData: SurveyQuestions[] = childData.filter(
      (question) => !question.attributes.parent
    );
    return resultData;

  }

  setInitialStateForForm(resultData: any) {
    console.log("setInitialStateForForm", resultData);
    
    const scoringQuestionExist = this.state.resultQueData.some((question: any) => question?.attributes.response_type === "scoring");
    const otherQuestionExist = this.state.resultQueData.some((question: any) => question?.attributes.response_type !== "scoring");
    const allreadySubmitted = this.calculateAllreadySubmitted(
      scoringQuestionExist,
      otherQuestionExist,
      this.state.surveyFilled,
      this.state.scorringSurveyFilled
    );
    const isFormDrafted = this.isDraftedCheck()
    if (allreadySubmitted || isFormDrafted) {
      this.showAnswer()
    }  

    let question_answers_attributes: any = [];
    resultData?.forEach((ques: any) => {
      const parentQuestionAnswers = this.getQuestionAnswersAttributes(ques);
      if (parentQuestionAnswers) {
        question_answers_attributes.push(parentQuestionAnswers);
      }
    });

    this.setState({
      surveyData: question_answers_attributes
    })
  }

  getQuestionAnswersAttributes = (question: any) => {
    const { attributes } = question;
    const responseTypeId = attributes.response_type_id;
    const questionId = question.id;
    const questionType = attributes.question_type;
    const answer = attributes.answer;
    const options = attributes.options;
    const answers = attributes.answers;

    switch (responseTypeId) {
      case 0:
        let answerData = ''
        if(Array.isArray(answers) && answers.length > 0) { 
        answerData = answers[0].answer
        }
      return {
        survey_question_id: questionId,
        option_id: null,
        question_type: questionType,
        answer: answerData ?? null,
        responseTypeId
      }
      case 1:
        let surveyResponse = this.getSurveyResponse(answers, questionId, questionType, responseTypeId);
        return surveyResponse;
      case 2:
        return options.map((opt: any) => ({
          survey_question_id: questionId,
          option_id: opt.id,
          question_type: questionType,
          answer: answers.find((ans: any) => ans.option_id === opt.id)?.answer || null,
          responseTypeId
        }));
      case 3:
        let optionCase3Id = this.getLastOptionId(answers)
        return {
          option_id: optionCase3Id ?? null,
          survey_question_id: questionId,
          question_type: questionType,
          responseTypeId
        };
      case 4:
        let optionDataId = null
        if(Array.isArray(answers) && answers.length > 0) { 
          optionDataId = answers[answers.length-1].scoring_option_id
          }
        return {
          survey_question_id: questionId,
          option_id: optionDataId ?? null,
          question_type: questionType,
          responseTypeId
        };
      default:
        return null;
    }
  };
  
  getSurveyResponse(answers: any, questionId: any, questionType: any, responseTypeId: any) {
    let optionId = null;
    let optionAnswer = null;

    if (Array.isArray(answers) && answers.length > 0) {
      optionId = answers[0].option_id;
      optionAnswer = answers[0].answer;
    }

    return {
      survey_question_id: questionId,
      option_id: optionId ?? null,
      question_type: questionType,
      answer: optionAnswer ?? null,
      responseTypeId
    };
  }
  getLastOptionId(answers: any) {
    let optionCase3Id = null;

    if (Array.isArray(answers) && answers.length > 0) {
      optionCase3Id = answers[answers.length - 1].option_id;
    }

    return optionCase3Id;
  }
  showAnswer = () => {
    this.setState(prevState => ({
      resultQueData: prevState.resultQueData.map((record: any) => {
        const { children } = record.attributes;
        if (this.isEmptyArray(children)) {
          // setting response_type for scoring
          children.map((data: any) => {
            if (data && data?.data.attributes.response_type_id !== 0 && !data?.data.attributes.response_type_id) {
              data.data.attributes["response_type_id"] = 4;
              data.data.attributes["response_type"] = "scoring";
            }
          });
          const nestedChildren = children.filter((childData: any) => {
            const { answers } = childData.data.attributes;
            console.log("children++",record,childData.data.attributes);
            return this.isEmptyArray(answers)
          })?.map((e: any) => e.data);

          if (nestedChildren.length > 0) {
            let nestedChildrenSort = this.isValidSortChild(nestedChildren)
            return {
              ...record,
              attributes: {
                ...record.attributes,
                nested_child: nestedChildrenSort
              }
            };
          }
        }
        return record;
      })
    }));
  }
  
  isEmptyArray = (arr: any) => Array.isArray(arr) && arr.length > 0;
  isDraftedCheck = () => {
    let isSurveyDrafted = this.state.surveyDrafted || false;
    let isScoringSurveyDrafted = this.state.scorringSurveyDrafted || false;
    let isScoringSubmitted = this.state.scorringSurveyFilled || false;
    let isSurveySubmitted = this.state.surveyFilled || false;
    return isSurveyDrafted || isScoringSurveyDrafted || isScoringSubmitted || isSurveySubmitted
  }
  inViewMode = (checkfilled:any, checkScoringfilled:any) => {
    return checkfilled || checkScoringfilled
  }

  navigateToActivityFeedPage = () => {
    toast.warn("Not an authorised user");
    this.getNavigationMessage('ActivityFeed',this.props)
  }

  navigateActivityFeedPage = () => {
    toast.warn("This survey is not live currently")
    this.getNavigationMessage('ActivityFeed',this.props)
  }

  getNavigationMessage = (route: string, props: Props) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
  
    this.send(message);
  }
    // Customizable Area End

}

