import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { getStorageData, setStorageData } from '../../../framework/src/Utilities';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  reportingCardsData: any[];
  loading: boolean;
  filterQuery: string;
  noData:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportingFrameworksController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getReportingCardCallId:string = "";
  currnetPath:string = "";
  user:any = {}
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      reportingCardsData: [],
      loading:true,
      filterQuery: '',
      noData: '',
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData('authToken')
  
    this.currnetPath = this.props.navigation.history.location.pathname;
    this.user = await getStorageData('user')
    if (token == null) {
      this.props.navigation.navigate('Home')
    }

    this.getReportingCardData()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({loading: false})
        this.parseApiCatchErrorResponse(responseJson.errors);
        this.parseApiErrorResponse(responseJson.errors);

      } else if(responseJson && responseJson.data){
        if(this.getReportingCardCallId == apiRequestCallId){
          this.formateData(responseJson)
        }
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start

  navigationMessage = (route: string, props: any, payloadData?: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);

    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    if (payloadData) {
      const raisePayloadDataMessage: Message = new Message(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      raisePayloadDataMessage.addData(getName(MessageEnum.ReportingFrameworksNavData), payloadData);
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raisePayloadDataMessage);
    }
    this.send(message);
  }

  formateData(reportingFrameworkData:any) {
    const routeName = this.currnetPath == '/ESGJourneyReportingFrameworks'  ? 'SelfAssesment' :  'MandatorySurveys'
    const CardData = reportingFrameworkData.data?.map((item: any) => ({
      title: item.attributes.title,
      icon: item.attributes.image,
      description: item.attributes.description,
      route: routeName,
      id: item.attributes.id
    }));
    this.setState({reportingCardsData: CardData , loading: false, noData:reportingFrameworkData.message})
  }

  onFilterReportingCards = (filterQuery: string) => {
    this.setState({filterQuery}, () => {
      this.getReportingCardData()
    })
  }

  getReportingCardData = async() => {
    let token = await getStorageData('authToken')
    
    this.getReportingCardCallId = sendAPIRequest(`${configJSON.getReportingFrameworkCardsEndPoint}?search=${this.state.filterQuery}`, {
      method: 'GET',
      headers: {token : token},
    })
  }
  getBackPath = ():string => {
    if (this.currnetPath === '/ESGJourneyReportingFrameworks') {
      if (this.user?.user_type) {
        return this.user.user_type == "licensed_user" ? "Corporatepermission" : "ESGJourney"
      } else {
        return 'ESGJourney'
      }
    } else {
      return 'SurveyCards'
    }

  }
  setData= async(title: string,id: number)=>{
    await setStorageData('reportingFrameworkTitle', title)
    await setStorageData('reportingFrameworkId', id)
  }
    // Customizable Area End

}