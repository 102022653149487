import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { toast } from 'react-toastify'
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otp2:any;
  otpAuthToken: any;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  firstnum:any;
  secondnum:any;
  thirdnum:any;
  fourthnum:any;
  fifthnum: string;
  isLoading:boolean;
  open:boolean;
  modalContent:string;
  modalHeading: string;
  otpAPIError: string;
  startTime: any;
  lastTime: any;
  counterId?: any;
  navTypeData: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo?: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  ResendOtpAuthApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp:"",
      otpAuthToken:"",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      firstnum:'',
      secondnum:'',
      thirdnum:'',
      fourthnum:'',
      fifthnum: '',
      otp2: this.state?.firstnum ? this.state?.firstnum+this.state?.secondnum+this.state?.thirdnum+this.state?.fourthnum:"",
      isLoading:false,
      open:false,
      modalContent:'',
      modalHeading: '',
      otpAPIError: '',
      startTime: new Date(),
      lastTime: new Date(),
      navTypeData:'',
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson.meta && responseJson.meta.token)
        await setStorageData("authToken", responseJson.meta.token)
      if (
        responseJson &&
        (responseJson.messages ||
          (responseJson.meta && responseJson.meta.token))
      ) {
        if (responseJson.meta && responseJson.meta.token) {
          // console.log(responseJson,"response")
          this.setState({
            otpAuthToken: responseJson.meta.token
          });
        }
        this.setState({isLoading:false})
        // localStorage.setItem('verifyToken',responseJson.meta.token);
        // this.props.navigation.navigate('NewPasswordWeb');
        let fPw = await getStorageData("navigationFrom")

        if (this.state.isFromForgotPassword || fPw==="forgotPw") {

          this.props.navigation.navigate('NewPasswordWeb');
          // msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          // this.send(msg);
        } else if (fPw==="email"){
          this.setState({isLoading:false})
          const tokenEmail:any= await getStorageData('accountToken');
          console.log(tokenEmail,"verifyEmail Token********")
          console.log("emailverify");
          // Bug fix, token sent from OTP Verify endpoint is used instead of below
          // localStorage.setItem('authToken',tokenEmail);
          removeStorageData('email');
          this.props.navigation.navigate('ActivityFeed');
          // this.send(msg);
        }else {
          console.log("page refreshed");
          this.setState({isLoading:false})
          toast.warn("No account found")
          // this.setState({open:true,modalContent:"No account found"})
        }
      } else {
        //Check Error Response
        const error = responseJson?.errors[0]
        // this.setState({open:true,modalContent: error?.pin || error?.otp || JSON.stringify(error), isLoading: false})
        this.setState({otpAPIError: responseJson?.errors?.map(Object.values).join(), isLoading: false})
        // this.parseApiErrorResponse(responseJson);
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse != null) {
        const error = responseJson?.errors[0]
        this.setState({otpAPIError: responseJson?.errors?.map(Object.values).join(), isLoading: false})
        // this.setState({open:true,modalContent: error?.pin || error?.otp || JSON.stringify(error), isLoading: false})
        // this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

      let updatedLabel = this.state.labelInfo;
      if (userAccountID && userAccountID !== "undefined") {
        updatedLabel = updatedLabel.replace("phone", userAccountID);
      }

      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool
      });
    }else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.ResendOtpAuthApiCallId != null &&
      this.ResendOtpAuthApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let navForm = await getStorageData("navigationFrom")
      if (responseJson)
      {
        let fPw = await getStorageData("navigationFrom")
      if (this.state.isFromForgotPassword || fPw==="forgotPw") {
       
        console.log(responseJson,'responseJson******')
        //   this.setState({isLoading:false})
         if(!responseJson.errors){
          toast.success("OTP is send to your email")
          // this.setState({open:true,modalContent:"OTP is send to your email"})
          await setStorageData('ResendToken',responseJson?.meta?.token);
          const resendToken:any= await getStorageData('ResendToken');
          await setStorageData('verifyToken',resendToken);
          console.log(resendToken);
         }else{
          const error = responseJson?.errors[0];
          const json = responseJson
          console.log({ errorJson: json })
          this.setState({otpAPIError: responseJson?.errors?.map(Object.values).join(), isLoading: false})
          // this.setState({open:true, modalContent: error || error?.otp || error?.pin || JSON.stringify(error)})
         }

      } else if (navForm==="email"){
       
        console.log(responseJson,'responseJson******')
        //   this.setState({isLoading:false})
        if(!responseJson.errors){
          toast.success("OTP is send to your email")
          // this.setState({open:true,modalContent:"OTP is send to your email", modalHeading: ""})
          setStorageData('ResendToken',responseJson?.meta?.token);
          const resendToken:any= await getStorageData('ResendToken');
          setStorageData('accountToken',resendToken);
        
        }else {
          const error = responseJson?.errors[0];
          const json = responseJson
          console.log({ errorJson: json })
          
          this.setState({otpAPIError: responseJson?.errors?.map(Object.values).join(), isLoading: false})

        }
      }else {
        console.log("page refreshed");
        this.setState({isLoading:false})
        this.setState({open:true,modalContent:"something went wrong", modalHeading: "Error"})
        toast.error("Something went wrong")
      }
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.setState({otpAPIError: configJSON.errorOtpNotValid, isLoading: false})

      // this.setState({open:true,modalContent:configJSON.errorOtpNotValid, modalHeading: "Error"})
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      // console.log("entered is from forgot password!");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken? this.state.otpAuthToken:"",
        otp_code: this.state.otp ? this.state.otp : ""
      };

      const httpBody = {
        data: data
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
     
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    // console.log("requestMessage.id is: " + requestMessage.id);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp()
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text })
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric"
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

    handleClose = () => {
      this.setState({open:false})
    };
    
     OnsubmitOtp=async(e:any) =>{
       e?.preventDefault?.();
      const navigationFrom = await getStorageData('navigationFrom');
      const token = await getStorageData('verifyToken');
      const tokenEmail = await getStorageData('accountToken');

       
       this.setState({isLoading:true})
      // const otp2 = this.state.firstnum+this.state.secondnum+this.state.thirdnum+this.state.fourthnum+this.state.fifthnum
      const otp2 = this.state.otp
      console.log(otp2);
      if (!otp2 || otp2.length === 0 || otp2.length < 5) {
        this.setState({otpAPIError:configJSON.errorOtpNotValid, isLoading:false, })
        return;
      }
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      
      if(this.state.isFromForgotPassword || navigationFrom==="forgotPw"){
        console.log('from forgot password');
        const header = {
          "Content-Type": configJSON.apiVerifyOtpContentType
        };
  
        //GO TO REQUEST STATE
        this.otpAuthApiCallId = requestMessage.messageId;
        
        const data = {
          token: token,
          otp_code: otp2 ? otp2 : ""
        };
  
        const httpBody = {
          data: data
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.otpVerificationApiEndPointWeb
        );
          console.log(configJSON.otpVerificationApiEndPointWeb);
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
  
       
  
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

      }else if(navigationFrom==="email") {
        console.log("from verify email");
        const header = {
          "Content-Type": configJSON.apiVerifyOtpContentType
        };
  
        //GO TO REQUEST STATE
        this.otpAuthApiCallId = requestMessage.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.apiVerifyOtpEndPointWeb
        );
          console.log(configJSON.apiVerifyOtpEndPointWeb);
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
  
        const data = {
          token: tokenEmail,
          otp_code: otp2 ? otp2 : ""
        };
  
        const httpBody = {
          data: data
        };
  
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );

      }else {
        this.setState({isLoading: false, otpAPIError:"Invalid OTP"});
        return false;
      } 
      // console.log("requestMessage.id is: " + requestMessage.id);
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleResendOTP = async() => {
      // e.preventDefault();
      // console.log(localStorage.getItem("email"))
      if (this.state.counterId) {
        return
      }
      this.resetTimer()

    //  window.location.reload();
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      //GO TO REQUEST STATE
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType
      };

      //GO TO REQUEST STATE
      this.ResendOtpAuthApiCallId = requestMessage.messageId;
      console.log("forgot password resend");
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.resendOtpVerificationApiEndPointWeb
      );
        console.log(configJSON.resendOtpVerificationApiEndPointWeb);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
        let uEmail = await getStorageData('email');
      const data = {
        attributes: {
          email: uEmail
        }
      };

      const httpBody = {
        data: data
      };

      requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
      console.log("post ")
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      console.log(httpBody);



      runEngine.sendMessage(requestMessage.id, requestMessage);
        
      }

      move(e:any,p:any,c:any,n:any){
        //@ts-ignore
        let length = document.getElementById(c)?.value?.length;
       
        let maxlength = document.getElementById(c)?.getAttribute('maxlength');
       
        if(length== maxlength){
          document.getElementById(n)?.focus();
        }
       if(e.key=="Backspace"){
        document.getElementById(p)?.focus();
       }

      }
  setOtp = (otp: string) => this.setState({ otp })
  
  componentDidMount = async () => {
    let authToken = await getStorageData("authToken")
    let navType = await getStorageData("navigationFrom")
    this.setState({ otpAuthToken:authToken, navTypeData:navType }, () => {
    this.startTimer()
    })
  }
  startTimer = () => {
    this.setState({ startTime: new Date(), lastTime: new Date(), counterId: setInterval(this.updateTimer, 1000)  })
  }

  updateTimer = () => {
    const { startTime } = this.state
    const time = new Date()
    // @ts-ignore
    if((time - startTime)/1000 > Number.parseInt(configJSON.otpValidationTime)) {
      this.stopTimer()
    }
    this.setState({ lastTime: time })
  }

  stopTimer = () => {
    clearInterval(this.state.counterId);
    this.setState({ counterId: undefined })
  }

  timeRemaining = () => {
    const { startTime, lastTime } = this.state
    const count = Math.floor(( lastTime - startTime)/1000)
    return Math.max(0, configJSON.otpValidationTime - count)
  }
  resetTimer=()=>{
   
   this.startTimer()
  }

  componentWillUnmount = async () => {
    this.stopTimer()
  }


  // Customizable Area End
}
