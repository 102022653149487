Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const config = require("../../../framework/src/config");
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.corporateProflieForm = "bx_block_peoplemanagement2/corporate_profile_cards"
exports.btnExampleTitle = "CLICK ME";
exports.getUserProfile = "account_block/accounts/user_profile/";
exports.followUserProfile = "bx_block_followers/follows";
exports.viewUserProfile = "account_block/account_views";
exports.requestApiCallEndpoint = "bx_block_request_management/requests";
exports.requestApiCallMethodType = "GET";
exports.getUserList = "bx_block_share/get_users";
exports.addRequest = "bx_block_request_management/requests";
exports.getRequestTypes = "bx_block_request_management/request_types";
exports.getAllRequests = "bx_block_request_management/requests";
exports.getRequestDetails = "bx_block_request_management/requests/:id"
exports.sendResponce = "bx_block_request_management/response";
exports.acceptRejectRequest = "bx_block_request_management/approve_or_reject_request";
exports.filterRequest = "bx_block_request_management/filter_request";
exports.getUsersEndpoint = "account_block/accounts/filter";
exports.getRequestedByEndpoint = "bx_block_surveys/surveys/account";
exports.createTemplateUniqueId = "bx_block_surveys/surveys/generate_uniq_id";
exports.createSurveyTemplate="/bx_block_surveys/due_deligience";
exports.getSurveyByIdEndpoint="/bx_block_surveys/due_deligience/:sid";
exports.getSurveyTemplates = "bx_block_surveys/due_deligience/my_surveys";
exports.addQuestionEndpoint="bx_block_surveys/surveys/:qid/survey_questions"
exports.getTaggedCommunities="bx_block_surveys/surveys/get_communities";
exports.getSurveyEditEndpoint="bx_block_surveys/due_deligience";
exports.deleteSurveyTemplate="bx_block_surveys/due_deligience/:id";
exports.getRequestorsEndpoint="bx_block_surveys/surveys/request_accounts";
exports.senEmailEndpoint = "bx_block_surveys/surveys/send_mail";
exports.getStackholderProfile = "bx_block_peoplemanagement2/stakeholder_profile_forms";
exports.getCorporatProfileData = "bx_block_peoplemanagement2/corporate_profile_cards";
exports.getReportURL = "bx_block_surveys/surveys/download_carbon_calculator";
exports.getCorporateGovernanceData="bx_block_peoplemanagement2/corporate_governances";
exports.stakeHolderProfileFOrms="bx_block_peoplemanagement2/stakeholder_profile_forms";
exports.editStakeHolder='bx_block_peoplemanagement2/stakeholder_profile_forms';
exports.addCorporateGovernance='bx_block_peoplemanagement2/corporate_governances';
exports.editCorporateGovernanace='bx_block_peoplemanagement2/corporate_governances';
exports.createBoradOperation='bx_block_peoplemanagement2/corporate_governances/create_board_operations_and_committee';
exports.editBoardOperations='bx_block_peoplemanagement2/corporate_governances/update_board_operations_and_committee';
exports.getStackholderProfile = "bx_block_peoplemanagement2/stakeholder_profile_forms"
exports.getCorporatProfileData = "bx_block_peoplemanagement2/corporate_profile_cards"
exports.getReportURL = "bx_block_surveys/surveys/download_carbon_calculator"
exports.getObjectiveIdOfRisk= "bx_block_risk_management/objective/generate_id"
exports.getAllObjectivesofRisk='bx_block_risk_management/objectives'
exports.PostObjectivesofRisk= 'bx_block_risk_management/objective/create_objective'
exports.deleteObjectiveIdofRisk = 'bx_block_risk_management/objective/delete'
exports.getIssuesandConcern = 'bx_block_risk_management/objective/get_issues'
exports.showRisk='bx_block_risk_management/objective/get_objective'
exports.EditRisk='bx_block_risk_management/objective/update'
exports.getRecommdedproject = 'bx_block_risk_management/objective/download_projects'
exports.getProjectEvalution = 'bx_block_project_evaluation/project_evaluation/generate'
exports.getUserList = "bx_block_project_evaluation/project_evaluation/search_all_approvers";
exports.getFinanceReport="bx_block_project_evaluation/project_evaluation/download_finance_sheet"
exports.getBoardOperationsData = 'bx_block_peoplemanagement2/corporate_governances/show_operations_commitee'
exports.getSponsorList =  'bx_block_project_evaluation/project_evaluation/search_sponsors'
exports.postproject = 'bx_block_project_evaluation/project_evaluation/create'
exports.getShowApi='bx_block_project_evaluation/project_evaluation/show'
exports.updateprojectEval = 'bx_block_project_evaluation/project_evaluation/update'
exports.getUpdateStatus = 'bx_block_project_evaluation/project_evaluation/check_status_access'
exports.updateStatus = 'bx_block_project_evaluation/project_evaluation/update_status/'
exports.deletedBoardOperationData = 'bx_block_peoplemanagement2/corporate_governances/delete_operations_commitee'
exports.DownloadUploadedFile = '/account_block/accounts/get_carbon_excel'
exports.riskAndOpportunity = 'bx_block_report/report/objectives'
exports.getProjects = 'bx_block_report/report/projects'
exports.getProjectsMileStone = 'bx_block_report/report/milestones'
exports.getProjectsActivity = 'bx_block_report/report/activities'
exports.downloadReportOfRisk = 'bx_block_report/download/objectives'
exports.downloadReportOfProjects = 'bx_block_report/download/projects'
exports.downloadReportOfProjectMilestone = 'bx_block_report/download/milestones'
exports.downloadReportOfProjectActivity = 'bx_block_report/download/activities'
exports.uploadCarbonFile ='/account_block/accounts/add_carbon_excel'
exports.getRecommendedProject='bx_block_risk_management/objective/get_report_data'
exports.importCsvEndpoint= "bx_block_surveys/surveys/import_file"
exports.downloadReportEndpoint = "bx_block_surveys/surveys"
exports.getStakeHolderManager ="bx_block_peoplemanagement2/stakeholder_profile_forms/stakeholder_manager_options"
exports.deleteCvDocument = "bx_block_peoplemanagement2/corporate_governances/delete_governance_user_cv"
exports.getAccessCardDate= "bx_block_abilities/abilities_cards/corporate_license_corporate_profile_card"
exports.getTaggedProject='bx_block_peoplemanagement2/corporate_profile_dashboards/get_user_projects'
exports.getTaggedCommunitiesWithPagination="bx_block_surveys/surveys/communities";
exports.deleteCertification="bx_block_peoplemanagement2/corporate_profile_cards";
exports.deleteEditObjectiveAttachmentEndPoint="bx_block_risk_management/objective/delete/pdf_documents";
exports.deleteProjectEvaluationAttachmentEndPoint="bx_block_project_evaluation/project_evaluation/delete/proposal_documents";
exports.deleteProjectEvaluationReferenceAttachmentEndPoint="bx_block_project_evaluation/project_evaluation/delete/reference_documents";
exports.deleteProjectEvaluationFinanceAttachmentEndPoint="bx_block_project_evaluation/project_evaluation/delete/finance_sheet";
exports.getStatusEndpoint = "bx_block_risk_management/objectives/permissions";
exports.googleClientId = config.googleCred.GOOGLE_PLACE_API;
exports.allViewers = "account_block/accounts/account_viewed_by"
exports.allFollowers = "/bx_block_followers/follows"
exports.addViewers = "account_block/account_views"
// Customizable Area End