import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { toast } from 'react-toastify';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token: string;
  // Customizable Area Start
  allProjects: any[];
  isEdit: boolean;
  modal: string;
  loading: boolean;
  isdisabled: boolean;
  total_pages: number | undefined,
  pages: number,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  userRoleCallId= '';
  getAllActivityCallId= '';
  getMilestoneCallId= '';
  generateActivityIdCallId= '';
  createActivityCallId= '';
  deleteActivityCallId= '';
  editActivityCallId= '';
  getPredecessorCallId= '';
  editSubmitActivityCallId= '';
  

  async componentDidMount() {
    super.componentDidMount();
    console.log("component mounted")
    const authToken = await getStorageData('authToken')
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
      const access = this.props?.navigation?.history?.location?.state?.ability_type
      console.log("access rights =", access);
      if (access == "read") {
        this.setState({ isdisabled: true })
      }
        this.getAllActivities();
    }

  }
  getAllActivities = async () => { 
    console.log("in activity")
    this.setState({loading: true})
    const token = await getStorageData('authToken');
    this.getAllActivityCallId = sendAPIRequest(`${configJSON.getAllProjects}page=${this.state.pages}`, {
      method: 'GET',
      headers: {token}
    })
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.log("constructor")

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token: '',
      // Customizable Area Start
      modal: '',
      allProjects: [],
      isEdit: false,
      loading: false,
      isdisabled: false,
      total_pages: undefined,
      pages: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if(responseJson) {
        switch(apiRequestCallId) {
          case this.getAllActivityCallId:
            console.log("all activity = ", responseJson);
            this.setState({allProjects: responseJson.evaluations.data, loading: false});
            const total_pages = responseJson?.evaluations?.meta?.pagination?.total_pages
            this.setState({ total_pages})
            break;
          case this.getMilestoneCallId:
            console.log("all milestone ids =",responseJson.data);
            break;
          default:
            console.log("Invalid apiRequestCallId");
            break;
        }
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  onBack = () => {
    this.props.navigation.goBack();
  }

  handleProjectEval = (id:any,state:boolean)=>{
    if(this.state.isdisabled){
      toast.warn("You do not have permission to perform this action")
    }
    else{
      const url = `EditEvaluation/${id}`
      
      this.props.navigation.history.replace({pathname:url,state})
    }
     
  }

  handlePageChange = (event: any, value: any) => {
    this.setState({ pages: value }, this.getAllActivities)
  }

  showProjectEval = (id:any,state:boolean)=>{
    const url = `ShowEvaluation/${id}`
    
    this.props.navigation.history.replace({pathname:url,state})
     
  }


  // Customizable Area End
  
}
