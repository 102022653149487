import React from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {Box, Grid,Typography, RadioGroup, FormControlLabel, Radio, TextField, Button, Popover} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Attachments from '../../../components/src/Attachments.web'
import { styled } from '@material-ui/styles'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { Formik, Field, FieldArray } from "formik";
import {DownloadIC} from './assets';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import OverlayLoader from '../../../components/src/Loader.web'
import { OptionAttributes } from './types';


import SurveyEnduserController, {
  Props,
} from "./SurveyEnduserController";
// Customizable Area End
export default class ChangePasswordWeb extends SurveyEnduserController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  attributeMapping: { [key: string]: any } = {
    'mandatory': <Mandatory data-test-id="question-mandatory-mark"> * </Mandatory>,
    'optional': ''
  }

  radioMapping =  (isViewMode: boolean, defaultValue: any, opt: any) => {
    if (isViewMode) {
      return <Radio data-test-id="view-radio-button" color="default" disabled checked={defaultValue == opt.id} />
    }
    return <Radio color="default" data-test-id="radio-input"/>
  }
  
  renderInputField = (ques: any, index: number, checkfilled: boolean, values: any,isDrafted:boolean) => {
    return (
      <Field
        id="subjectiveField"
        key={`subjectiveField-${index}-${ques.id}`}
        name={`Questions[${index}]`}
        render={({ field, form: { touched, errors } }: any) => (
          <SummationTextWrapper
            id="subjectiveFieldTextInput"
            name={`Questions[${index}].ques`}
            defaultValue={isDrafted ? ques.attributes.answers[0]?.answer : ''}
            disabled={checkfilled}
            onChange={(e: any) => this.handleSubjectiveChange(e, values, ques.id)}
            variant="outlined"
          />
        )}
      />
    );
  }; 

  renderTotalSum = (questionType:number,checkfilled:boolean,ques:any,isDrafted:boolean) => {
    if (questionType === 2 && (checkfilled || isDrafted)) {
      const totalSum = this.totalSumAnswers(ques.attributes);
      return <TotalSumContainer data-test-id="summation-total">Total: {totalSum}</TotalSumContainer>;
    }
    return null;
  };


  getRadioGroupValue = (responseTypeId: number, ques: any, checkfilled: boolean, checkScoringfilled: boolean,index:number,isDrafted:boolean) => {
    let answersLength = 1 
    if(ques?.attributes?.answers?.length){
      answersLength = ques?.attributes?.answers?.length
    }
    switch (responseTypeId) {
      case 1:
      case 3:
        return (checkfilled || isDrafted)  ? ques?.attributes?.answers[answersLength-1]?.option_id : 0;
      case 4:
        return (checkScoringfilled || isDrafted) ? ques?.attributes?.answers[answersLength-1]?.scoring_option_id : 0;
      }
  };
  

  getFormControlLabelValue = (responseTypeId: number, opt: OptionAttributes, checkfilled: boolean, checkScoringfilled: boolean,isDrafted:boolean) => {
    const isResponseType1Or3 = responseTypeId === 1 || responseTypeId === 3;
    const responseForType1Or3 = (checkfilled) ? opt.id : opt.title
    const responseForType4 = (checkScoringfilled) ? opt.id : opt.title
    const valueToUse = isResponseType1Or3 ? responseForType1Or3 : responseForType4
        return valueToUse
  };

  renderQuestion(ques: any, index: number, checkfilled: boolean, checkScoringfilled: boolean, values: any,isDrafted:boolean, parentIndex?: number){
    const questionAttributes = ques.attributes;
    const questionType = questionAttributes.response_type_id;
    const unique_question_id = ques.attributes?.unique_question_id
    const renderRadioGroup = () => {
      const responseTypeId = ques?.attributes?.response_type_id;
      const defaultValue = this.getRadioGroupValue(responseTypeId, ques, checkfilled, checkScoringfilled, index,isDrafted)
      const isViewMode = this.inViewMode(checkfilled, checkScoringfilled)
      return (
        <Field
          key={`renderRadioGroup-${index}-${ques.id}`}
          id="radioGroupID"
          name={`Questions[${index}]`}
          render={({ field, form: { touched, errors } }: any) => (
            <RadioGroup aria-label="gender"
            id="RadioGroup"
            data-testid="RadioGroupBtn"
            defaultValue={this.getRadioGroupValue(responseTypeId, ques, checkfilled, checkScoringfilled,index,isDrafted)}
              onChange={(e: any) => this.handleMultipleSelect(e, values, ques.id, index,questionType,isDrafted, parentIndex)}
            >
              {ques.attributes.options.map((opt: OptionAttributes) => {
                console.log("values.question_answers_attributes++",ques.id,values.question_answers_attributes[index]);
                
                const valueToUse = this.getFormControlLabelValue(responseTypeId, opt, checkfilled, checkScoringfilled,isDrafted)
                let isChecked;
                const queationData = values.question_answers_attributes.find((data:any) => data.survey_question_id == ques.id)

                if (isDrafted && !queationData?.option_id) {
                  isChecked = defaultValue == opt.id;
                } else {
                  isChecked = queationData?.option_id == opt.id;
                }
                
                return (<FormControlLabel
                  id="radioLabel"
                  data-testid="optionRadioLabel"
                  key={opt.id}
                  value={valueToUse}
                  checked={isChecked}
                  control={this.radioMapping(isViewMode, defaultValue, opt)}   
                                    label={opt.title}
                />)
              })}
            </RadioGroup>
          )}
          />
      );
    };
    
    const renderSummationFields = () => {
      return (
        <>
          {ques.attributes.options.map((opt: any, innerIndex: number) => (
            <Field
              id="summationField"
              key={opt.id}
              name={`Questions[${index}]`}
              render={({ field, form: { touched, errors } }: any) => (
                <DivWrapper id="summationWraper">
                  <SummationTextWrapper
                    id="summation-title"
                    variant="outlined"
                    disabled
                    value={opt.title}
                  />
                  <SummationTextWrapper
                    variant="outlined"
                    id="summation-value"
                    data-testid ="summationText"
                    defaultValue={isDrafted ? ques?.attributes?.answers[innerIndex]?.answer: null}
                    disabled={checkfilled}
                    onKeyPress={(e: any) => this.allowOnlyNumbers(e)}
                    onChange={(e: any) => this.handleSummationChange(e, values, opt.id, ques.id,index, parentIndex)}
                  />
                </DivWrapper>
              )}
            />
          ))}
        </>
      );
    };
    const renderQuestions = () => {
      switch (questionType) {
        case 0:
          return this.renderInputField(ques, index, checkfilled, values,isDrafted);
        case 1:
        case 3:
        case 4:
          return renderRadioGroup();
        case 2:
          return (
            <>
              {renderSummationFields()}
              {this.renderTotalSum(questionType, checkfilled, ques,isDrafted)}
            </>
          );
        default:
          return null;
      }
    };
    const questionText = `${questionAttributes?.question} (${this.handleQuestionString(questionAttributes?.response_type)})`;
    const indexLabel = this.getIndexLabel(index, parentIndex)
    const questionPaperKey = `Question-${indexLabel}`;

    const renderNestedChildren = (parentIndex: number) => {
      if (this.isEmptyArray(questionAttributes?.nested_child)) {
        return (
          <div>
            {questionAttributes.nested_child.map((childQuestion: any, childIndex: number) => (
              <div key={`nested_child_${childIndex}`}>
                {this.renderQuestion(childQuestion, childIndex, checkfilled, checkScoringfilled, values,isDrafted, parentIndex)}
              </div>
            ))}
          </div>
        );
      }
      return null;
    };
    return (
      <QuestionPaper data-test-id="question-main-wrapper" key={questionPaperKey}>
        <QuestionTypography data-test-id="question-text">{indexLabel} <span style={{ color: "gray" }}> {unique_question_id} </span>{questionText}{this.attributeMapping[questionAttributes.question_type]}</QuestionTypography>
        {renderQuestions()}

        <Attachment data-test-id="question-Attachment" >
          <Attachments data-test-id="question-Attachments"files={(questionAttributes.question_attachments || []).map((j: any) => ({ ...j }))}/>
        </Attachment>
        
        {renderNestedChildren(index)}
      </QuestionPaper>
    );
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    let Questions = this?.state?.resultQueData
    const scoringQuestionExists = this.state.questions.some(question => question?.attributes.response_type === "scoring");
    const otherQuestionExists = this.state.questions.some(question => question?.attributes.response_type !== "scoring");

    let checkfilled = this.state.surveyFilled || false;
    let checkScoringfilled = this.state.scorringSurveyFilled || false;
    let isDrafted = this.isDraftedCheck()
    const allreadySubmitted = this.calculateAllreadySubmitted(
      scoringQuestionExists,
      otherQuestionExists,
      checkfilled,
      checkScoringfilled
    );
    const tempId = this?.props?.navigation?.getParam('id');
      console.log("tempId++++++++++++++++",this.state.surveyData);
      
    let initialValuesData: any = {
      "survey_id": tempId,
      "question_answers_attributes": this.state.surveyData,
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <MainBox id="main-wrapper">
        <Box data-test-id="top-navbar">
          <TopNavBar navigation={this.props.navigation} id={""} />
        </Box>

        
        <OuterGrid id="OuterGrid" container spacing={3}>
          <LeftGrid id="LeftGrid" item xs={12} sm={12} md={3} lg={3}>
            <Box data-test-id="left-menu" >
              <LeftMenuWeb data-test-id="left-menu-component" navigation={this.props.navigation} id={""} />
            </Box>
          </LeftGrid>
          <MiddleGrid id="MiddleGrid" item xs={12} sm={12} md={6} lg={6} >
            <Formik
              enableReinitialize
              initialValues={initialValuesData}
              onSubmit={(values, { setSubmitting }) => {
                this.setState({ loading: true})
                this.handleResponseSubmit(values, setSubmitting,false)
              }  
            }
            >
              {({
                values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,setSubmitting
              }) => (
                <form id="questions-form" onSubmit={handleSubmit}>
                  <SurveyBox data-test-id="main-box-wrapper">
                  <HeadingWrapper data-test-id="HeadingWrapper">
                          <HeadWrapper id="HeadWrapper">
                            <Head id="Head">
                              <ArroWrapper id="backArrow">
                                <ArrowBackIosIcon data-test-id='backbutton' onClick={this.onBack}/>
                              </ArroWrapper>
                                {this.state?.surveyName}
                            </Head>
                        </HeadWrapper>
                    {allreadySubmitted &&
                      (<RoundedButtonBase
                                    aria-describedby={this.state.anchorElForPopup ? 'simple-popover' : undefined}
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.setState({...this.state, anchorElForPopup: event.currentTarget})}
                                    variant="contained"
                                    startIcon={<img src={DownloadIC} width={20} />}>
                                    DOWNLOAD
                        </RoundedButtonBase>)}
                        <Popover
                          id={this.state.anchorElForPopup ? 'simple-popover' : undefined}
                          open={Boolean(this.state.anchorElForPopup)}
                          anchorEl={this.state.anchorElForPopup}
                          onClose={(event: React.MouseEvent<HTMLButtonElement>) => this.setState({...this.state, anchorElForPopup: null})}
                          anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                          }}
                          style={{marginTop: '10px'}}
                        >
                          <PopupWrapper >
                            <PopupText onClick={() => this.onHandleDownload(tempId , "pdf")}>Download as PDF</PopupText>
                            <PopupText data-test-id ="d-xls" onClick={() => this.onHandleDownload(tempId, "excel")}>Download as XLS</PopupText>
                          </PopupWrapper>
                        </Popover>
                        </HeadingWrapper>
                    <FieldArray
                      data-test-id="QuestionsFieldArray"
                      name="Questions"
                      render={(arrayHelpers:any) => (
                        <QuestionListWrapper data-test-id="question-list">
                          {Questions?.map((ques: any, index: number) => this.renderQuestion(ques, index, checkfilled, checkScoringfilled, values,isDrafted))}
                        </QuestionListWrapper> 
                      )}
                      /> 
                    {!allreadySubmitted &&
                    (<ButtonArea id="button-area">
                      <CancelButton data-test-id="CancelButton" disabled={allreadySubmitted} onClick={this.handleCancel}>Cancel</CancelButton>
                      <SubmitButton data-test-id="SubmitButton" disabled={allreadySubmitted || this.state?.isdisabled} type="submit">Submit</SubmitButton>
                       <span data-testid="OR-separator">OR</span>
                      <SubmitButton data-testid="SaveAsDraftButton" disabled={allreadySubmitted || this.state?.isdisabled} onClick={() => this.handleResponseSubmit(values, setSubmitting,true) }>Save As Draft</SubmitButton>
                    </ButtonArea>)}
                    {allreadySubmitted &&
                      (<AllreadySubmittedWrapper data-test-id="allreadySubmittedWrapper">
                      <OfflinePinIcon data-test-id="pin-icon" color='inherit'/>
                      <FilledArea data-test-id="allready-submited-message">You have already filled this Survey</FilledArea>
                    </AllreadySubmittedWrapper>)}
                  </SurveyBox>
                </form>
              )}
            </Formik>
          </MiddleGrid>
        </OuterGrid>

      </MainBox>
      <OverlayLoader data-test-id="loader" loading={this.state.loading} />
      </>
      // Customizable Area End   
      );
  }
}

// Customizable Area Start

const MainBox = styled(Box)({
  backgroundColor: "#edebeb",
  display: "flex",
  flexWrap: 'wrap',
  flexDirection: "column",
  paddingBottom: "20px",
  overflowX: "hidden",
})

const QuestionListWrapper = styled('div')({
})

const AllreadySubmittedWrapper = styled('div')({
  display:'flex', 
  color: 'green'
})

const HeadWrapper = styled('div')({
  display:'flex', 
  justifyContent: 'space-between', 
  alignItems: 'center', 
  width: '100%'
})
const SummationTextWrapper = styled(TextField)({
  width: '100%', 
  margin: '10px 0px'
})

const PopupWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const PopupText = styled('div')({
  cursor: 'pointer',
  padding: '20px 40px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
 },
})

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
})

const HeadingWrapper = styled('div')({
  display:"flex", 
  justifyContent: "space-between", 
  alignItems: 'center',
})

const LeftGrid = styled(Grid)({
  maxWidth: '300px',
  '@media screen and (max-width: 985px)': {
    maxWidth: '200px',
  },
  '@media screen and (max-width: 768px)': {
    display: 'none',
    visibility: 'hidden',
  },
})

const MiddleGrid = styled(Grid)({
  width: 'calc(100vw - 300px)',
  maxWidth: 'unset',
  flexBasis: 'unset',
  justifyContent: 'center',
  '@media screen and (max-width: 768px)': {
    width: '100%'
  }
})

const RoundedButtonBase = styled(Button)({
  backgroundColor: '#009c05',
  color: 'white',
  borderRadius: '20px',
  width: '160px',
  '&:hover': {
      backgroundColor: '#009c05',
  },
  '@media screen and (max-width:600px)': {
      width: '100%'
  },
  '@media screen and (min-width:769px) and (max-width:1200px)': {
      width: '100%'
  }
})

const SurveyBox = styled(Box)({
  width: '95%',
  background: '#fff',
  height: 'auto',
  marginTop: '30px',
  marginRight: '20px',
  padding: '20px',
})

const ArroWrapper = styled('div')({
  color: 'gray',
  marginRight: '10px',
  fontWeight: 100
})

const QuestionPaper = styled(Box)({
  // width: '100%',
  height: 'auto',
  padding: ' 20px 10px',
  marginBottom: '20px',
  boxShadow: '1px 2px 20px #CABFC1',
  borderRadius: '10px'
})

const QuestionTypography = styled(Typography)({
  fontWeight: 600,
  wordBreak: "break-all"
})

const ImageContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '20px'
})

const Head = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#000',
  margin: '10px 0px 30px 12px',
  fontSize: '1.3rem',
  fontFamily: 'HelveticaNeue',
  fontWeight: 600,
  '@media screen and (max-width: 768px)': {
    margin: '20px 0px 10px 12px',
  },
})

const DivWrapper = styled('div')({
  width: '70%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    width: '100%',
    flexDirection: 'row',
  }
})

const TotalDiv = styled('div')({
  width: '70%',
  display: 'flex',
  justifyContent: 'flex-end'
})

const AttachmentDiv = styled(Typography)({
  display: 'flex',
  marginTop: '10px',
  fontWeight: 600
})

const Attachment = styled('div')({
  padding: '5px 0',
  marginTop: '10px',
  marginBottom: '5px',
  minWidth: '250px',
  '@media screen and (max-width:480px)': {
    '& > div > div > div': {
      width: '170px',
      height: '35px',
    },
  },
  '& > div > p': {
    display: 'none',
  }
})

const ButtonArea = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: '10px',
})

const CancelButton = styled(Button)({
  background: 'gray',
  color: '#fff',
  fontSize: '0.75rem',
  '&:hover': {
    background: 'gray',
  }
})

const SubmitButton = styled(Button)({
  background: '#0fa60f',
  color: '#fff',
  fontSize: '0.75rem',
  '&:hover': {
    background: '#0fa60f',
  }
})

const RightGrid = styled(Grid)({
  maxWidth: '300px',
  '@media screen and (max-width: 985px)': {
    maxWidth: '250px',
  },
  '@media screen and (max-width: 768px)': {
    maxWidth: 'auto',
    width: '100%'
  },
})

const Mandatory = styled('span')({
  color: 'red',
})

const FilledArea = styled(Typography)({
  color: 'green',
  fontWeight: 600,
  marginLeft: '10px'
})

const TotalSumContainer = styled('div')({
  display: 'flex',
  marginRight: '30%',
  flexDirection: 'row-reverse',
  paddingBottom: '10px',
});


// Customizable Area End
