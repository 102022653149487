import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// @ts-ignore
import * as config from "../../../framework/src/config";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'
import generatePassword from 'generate-password';
import { getStorageData, logoutUser, setStorageData } from "../../../framework/src/Utilities";
var QuickBlox = require('quickblox/quickblox.min').QuickBlox;
var QB = new QuickBlox();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  first_name: string,
  last_name:string,
  location:string,
  currency:any,
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  showPassword:boolean;
  checked:boolean;
  preferences:any[];
  passwordError:string;
  isLoading:boolean;
  allPreferences: {id: number, name: string}[];
  showTerms: boolean;
  termsUrl: string;
  socialLoading: string;
  socialEmail: string;
  socialMethod: string;
  socialValues: any;  
  unique_auth_id: string;
  formInlineError: string;
  apiErrors?: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  name:RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  listPreferencesCallId: string = "";
  currentCountryCode: any;
  termsAndConditionCallId: string = "";
  socialSignupCallId: string = "";
  formInitialValues = {
    first_name: '',
    last_name: '',
    email: '',
    location: '',
    currency: '',
    password: '',
    preferences: [],
    checkbox: false,
  }
  validationSchema = Yup.object().shape({
    first_name: Yup
      .string()
      .required()
      .min(2)
      .max(50)
      .label('First Name')
      .matches(/^[a-zA-Z ]+$/, "First Name can only contain alphabets"),
    last_name: Yup
      .string()
      .required()
      .min(2)
      .max(50)
      .label('Last Name')
      .matches(/^[a-zA-Z ]+$/, "Last Name can only contain alphabets"),
    email: Yup
      .string()
      .required()
      .email()
      .label('Email'),
    password: Yup
      .string()
      .required()
      .min(6)
      .label('Password')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,32})/, "Password must contain atleast one special character, one capital letter, One lowercase letter and one digit."),
    location: Yup
      .string()
      .required()
      .min(2)
      .label('Location'),
    checkbox: Yup
      .boolean()
      .oneOf([true], "Agree terms and condition to proceed."),
    preferences: Yup
      .array()
      .min(1, "Please select atleast one preference")
      .required()
      .label("Preferences"),
  })
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      first_name: "",
      last_name:"",
      location:"",
      currency:"",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      showPassword: false,
      checked:false,
      preferences:[],
      passwordError:"",
      isLoading:false,
      allPreferences: [],
      showTerms: false,
      termsUrl: '',
      socialLoading: '',
      socialEmail: '',
      socialValues: [],
      unique_auth_id: "",
      formInlineError: "",
      socialMethod:'',
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/");
    this.emailReg = new RegExp("\\w+");
    this.name= new RegExp("/^[a-zA-Z ]{2,40}$/");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let authToken = await getStorageData("authToken")

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            if(this.state.socialMethod) {
              console.log({responseJson})
              const token = responseJson.meta.token
              this.context.set({ token, ...responseJson?.data?.attributes })
              await setStorageData("authToken", token)
              this.sendSessionUpdateMessage({
                id: responseJson?.data?.id,
                ...responseJson?.data?.attributes
              })
              setTimeout(()=>this.props.navigation.navigate("ActivityFeed"), 100);
              return;
            }
            this.setState({isLoading:false})
              await setStorageData('accountToken',responseJson.meta.token);
              await setStorageData('email', this.state.email);
              await setStorageData('navigationFrom',"email");
              this.props.navigation.navigate('ForgotPasswordOTPWeb',);
            // const msg: Message = new Message(
            //   getName(MessageEnum.AccoutResgistrationSuccess)
            // );
            
            // msg.addData(
            //   getName(MessageEnum.NavigationPropsMessage),
            //   this.props
            // );

            // this.send(msg);
          } else {
            //Check Error Response
            this.setState({isLoading:false})
            const lookup = new Set(Object.keys(this.formInitialValues))
            const formInlineError = (responseJson?.errors||[]).map((err:any)=>Object.entries(err)[0]).filter((err: any)=>{
              return !lookup.has(err[0])
            }).map((err: any)=>err[1]).join(', ')
            this.setState({ apiErrors: responseJson?.errors, formInlineError })
            // this.setState({ formInlineError: Object.values(responseJson?.errors[0])[0] as string })
            console.error(responseJson?.errors)
            // this.parseApiErrorResponse(responseJson);
          }
         
        } else if(apiRequestCallId === this.listPreferencesCallId) {
          if(responseJson?.data) {
            const allPreferences = responseJson?.data.map((data:any) => data.attributes)
            this.setState({ allPreferences })
          }
        } else if (apiRequestCallId === this.termsAndConditionCallId) {
          if(responseJson?.file) {
            this.setState({ termsUrl: responseJson?.file })
          }
        } else if (apiRequestCallId === this.socialSignupCallId) {
          if(responseJson?.email) {
            this.setState({ socialEmail: responseJson?.email, socialMethod: "linkedin", socialLoading: "", socialValues: [['first_name', responseJson?.first_name], ['last_name', responseJson?.last_name]] })
          } else {
            toast.warn("Could not retrieve your email")
          }
        }
       
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  sendSessionUpdateMessage = (user: any) => {
    const msg = new Message(getName(MessageEnum.SessionUpdateMessage))
    msg.addData(
      getName(MessageEnum.SessionUpdateData),
      user
    )
    runEngine.sendMessage(msg.id, msg)
  }

  location=()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({location:position.coords.latitude+ " ," +position.coords.longitude})
      });
      
    }
    
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition = (e?: any) => {
    // const msg: Message = new Message(
    //   getName(MessageEnum.NavigationTermAndConditionMessage)
    // );
    // msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // this.send(msg);
    // console.log(this.props.navigation.navigate('TermsWeb'))
    e && e.stopPropagation();
    // console.log("clicked t&c", new Date())
    this.setState({ showTerms: true })
    // window && window?.open?.(this.state.termsUrl, '_blank')
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isStringLength(str:string){
    return str.length >=10;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  };

  handleChangeChecked = (event:any) => {
    this.setState({...this.state, checked:event.target.checked},()=>console.log(this.state.checked));
    
  };

  handleChange = (prop:any) => (event:any) => {
    this.setState({ ...this.state, [prop]: event.target.value });
    // console.log(this.state.email,"values")
  };

  handleChangePreference = (chip:any) => {
    this.setState({
      preferences: [...this.state.preferences, chip] //shorthand way to copy an array and add a new element
    });
  };

  handleDelete = (chipToDelete: any) => () => {
    this.setState({
      preferences: this.state.preferences.filter(c => c != chipToDelete) //filter will produce a copy of the array
    });
  };
    
  handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };
    
  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  
  handleChangeCopy=(e:any)=>{
    e.preventDefault();
  }
  
    
  createSignUpAccount=(e:any)=> { //works when submit button
    e?.preventDefault?.();
    this.setState({isLoading:true});
    this.setState({
      first_name: e.first_name,
      last_name: e.last_name,
      email: e.email,
      preferences: e.preferences,
      location: e.location,
      currency: (e.location.toLowerCase()).includes("india") ? "inr" : "usd",
      password: e.password,
      checked: e.checkbox,
    })
    const { socialMethod } = this.state
    const socialTypes = ["google","microsoft","linkedin"]
    if(!this.state.socialMethod){
      this.createAccountApi();
    }else if (socialTypes.includes(socialMethod)) {
      this.createAccountApi("social");
    }

  };
  delay = (ms:number) => new Promise((resolve) => setTimeout(resolve, ms));
  createRandomPassword = () => {
    const passwordOptions = {
      length: 12, 
      numbers: true,
      symbols: false,
      uppercase: true,
      excludeSimilarCharacters: true,
      strict: true,
    };
    const password = generatePassword.generate(passwordOptions);
    return "id" + password;
  }
  createUserWithQuickBlox = (thisvar:any, password:string, type?:string) => {
    const { email, first_name, last_name } = thisvar.state;
    const fullName = `${first_name} ${last_name}`;
  
    const userParams = {
      login: email,
      password: password,
      full_name: fullName
    };
  
    QB.users.create(userParams, (error:any, result:any) => this.quickBloxCreateuser(error, result,thisvar,password,type))
    ;
  };
  quickBloxCreateuser =  (error:any, result:any,thisvar:any,password:string,type?:string) => {
    if (error) {
      this.setState({isLoading:false});
     toast.error("User Already Exist");
    }

    let attrs:any = {
      first_name: thisvar.state.first_name,
      last_name: thisvar.state.last_name,
      email: thisvar.state.email,
      password: thisvar.state.password,
      location: thisvar.state.location,
      currency: thisvar.state.currency,
      qb_id: result.id,
      qb_pass: password,
      qb_login: thisvar.state.email,
      account_preferences_attributes: thisvar.state.preferences.map((preference_id:any) => ({ preference_id }))
    };
    let typeData
    if (type === "social") {
      const socialTypes = ["google","microsoft","linkedin"]
      if (socialTypes.includes(thisvar.state.socialMethod)) {
        attrs.unique_auth_id = thisvar.state.unique_auth_id;
      }
      typeData = thisvar.state.socialMethod;
    } else {
      typeData = "email_account";
    }
    const data = {type: typeData,
      attributes: attrs}
      const httpBody = {
        data: data,
        ...(type !== "social" && thisvar.state.otpAuthToken ? { token: thisvar.state.otpAuthToken } : {})
      };
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    thisvar.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPointWeb
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  quickBloxCreateSession = async(error:any, result:any,thisvar:any,type?:string) => {
    if (error) {
      this.setState({isLoading:false});
     toast.error("User Already Exist");
    }
    const password =  this.createRandomPassword();
    await this.delay(2000);
    this.createUserWithQuickBlox(thisvar, password, type);

  }
  createAccountApi = async(type?:string) => {
    const APPLICATION_ID = config.QuibloxCred.QUICKBLOX_APPLICATION_ID;
    const AUTH_KEY = config.QuibloxCred.QUICKBLOX_AUTH_KEY;
    const AUTH_SECRET = config.QuibloxCred.QUICKBLOX_AUTH_SECRET;
    const ACCOUNT_KEY = config.QuibloxCred.QUICKBLOX_ACCOUNT_KEY;
    
    let thisvar = this;
    QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY);
    await this.delay(3000)
    QB.createSession((error:any, result:any) => this.quickBloxCreateSession(error, result,thisvar,type));
  };


  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      // this.showAlert(
      //   configJSON.errorTitle,
      //   configJSON.errorAllFieldsAreMandatory
      // );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      // this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      // this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      // this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      // this.showAlert(
      //   configJSON.errorTitle,
      //   configJSON.errorBothPasswordsNotSame
      // );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  
  getTermsAndCondition = () => {

    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const msg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsAndConditionCallId = msg.messageId;

    msg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsAndConditionEndpoint
    );

    msg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    msg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(msg.id, msg);
  }
  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  signin = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };


  loadPreferences = () => {
    const callId = sendAPIRequest(configJSON.preferencesListEndpoint, {method: 'GET', headers: {}})
    this.listPreferencesCallId = callId
  }

  handleGoogleLogin = (data: any) => {
    const {email, googleId, imageUrl, givenName, familyName,name, ...all } = data.profileObj
    this.setState({ socialEmail: email, socialMethod: "google", unique_auth_id: googleId, socialLoading: "", socialValues: [['first_name', givenName||name], ['last_name', familyName]] })
  }

  handleMicrosoftLogin = async(error: any, data: any, ) => {
    await logoutUser() // clear auth persistance
    this.setState({ socialLoading: "" })
    if(error) return
    const { email, oid } = data.account.idToken
    if(!email) {
      toast.warn("Email ID not found, try another login method.")
    }
    this.setState({ socialEmail: email, socialMethod: "microsoft", unique_auth_id: oid, socialLoading: "" })
  }

  handleLinkedinLogin = async(code: any) => {
    await logoutUser() // clear auth persistance
    if(!code) {
      toast.warn("Invalid Login Details, Please try again.")
    }
    this.setState({ unique_auth_id: code })
    const redirect_url = `${window.location.origin}/LinkedinRedirect`
    const url = configJSON.linkedinSignupEndpoint + '?' + String(new URLSearchParams({redirect_uri: redirect_url, auth_token: code})) 
    this.socialSignupCallId = sendAPIRequest(url, {
      method: 'POST',
      body: {},
      headers: { 'Content-Type': 'application/json' }
    })
  }
  // Customizable Area End
}
