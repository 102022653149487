import React, { RefObject, useEffect, useState }  from 'react'


import {runEngine} from '../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../framework/src/Messages/MessageEnum'
import { Message } from "../../framework/src/Message";

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

interface RequestParams {
	method?: HttpMethods;
	headers?: object;
	body?: object | FormData | any;
}

/**
* generic methods to construct and send an RestAPIRequestMessage
* returns callId - message id of the sent message, used to read response later
*/
export function sendAPIRequest(endpoint: string, params?: RequestParams) {
	const { method="GET", headers, body } = params || {}
	const requestMessage = new Message(
		getName(MessageEnum.RestAPIRequestMessage)
	)
	const callId = requestMessage.messageId
	requestMessage.addData(
		getName(MessageEnum.RestAPIResponceEndPointMessage),
		endpoint
	)

	requestMessage.addData(
		getName(MessageEnum.RestAPIRequestMethodMessage),
		method
	)

	if(body) 
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			body?.append ? body : JSON.stringify(body)
		)		
	
	if(headers)
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			headers
		)

	runEngine.sendMessage(requestMessage.id, requestMessage)

	return callId
}


export function useDebounce(value: string, delay: number){
	const [debounced, setDebounced] = React.useState(value)

	React.useEffect(() => {
		const t = setTimeout(() => setDebounced(value), delay)
		return () => clearTimeout(t)
	}, [value, delay])

	return debounced

}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState<{width: number, height: number}>({
    width:-1,
    height: -1,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}



interface Args extends IntersectionObserverInit {

  freezeOnceVisible?: boolean

}


export function useIntersectionObserver(

  elementRef: RefObject<Element>,

  {

    threshold = 0,

    root = null,

    rootMargin = '0%',

    freezeOnceVisible = false,

  }: Args,

): IntersectionObserverEntry | undefined {

  const [entry, setEntry] = useState<IntersectionObserverEntry>()


  const frozen = entry?.isIntersecting && freezeOnceVisible


  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {

    setEntry(entry)

  }


  useEffect(() => {

    const node = elementRef?.current // DOM Ref

    const hasIOSupport = !!window.IntersectionObserver


    if (!hasIOSupport || frozen || !node) return


    const observerParams = { threshold, root, rootMargin }

    const observer = new IntersectionObserver(updateEntry, observerParams)
    observer.observe(node)
    console.log(node, observer)
    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen])


  return entry

}



export function formatMoneyINR(value: any): string {
    const val =  value.toLocaleString('hi', { style: 'currency', currency: 'INR' });
    return val.split('.')[0]
}

export function formatMoneyUSD(value: any): string {
    const val = value.toLocaleString('en-us', { style: 'currency', currency: 'USD' });
    return val.split('.')[0]
}
