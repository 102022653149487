import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { parseNewQuestionMessage,} from "./Messages";
import { sendAPIRequest } from '../../../components/src/utils';
import { sendSharePopupMessage } from '../../share/src/Messages';
import { toast } from 'react-toastify';
import {
  imageIcon,
  videoIcon,
  csvIcon,
  pdfIcon,
  docIcon,
  xlsIcon,
  genericIcon
} from './assets';
import { Attachment,QuarterData,QuarterDetail,QuarterAttributes } from './types';
import moment from 'moment'
import { getStorageData } from "../../../framework/src/Utilities";

interface SurveyQuestion {
  id: string;
  type: string;
  attributes: {
    question: string;
    question_type: string;
    response_type: string;
    sequence_no: number;
    question_condition_type: null | string;
    parent: null | {
      id: string;
      survey_id: number;
      question: string;
      question_type: string;
      response_type: string;
      created_at: string;
      updated_at: string;
      sequence_no: number;
      question_condition_type: null | string;
      parent_id: null;
      parent_expected_answer: any[];
    };
    parent_expected_answer: any[];
    response_type_id: number;
    options: {
      id: number;
      title: string;
      is_deleted: boolean;
    }[];
    answers: any[];
    question_attachments: null;
    childQue?: SurveyQuestion[];
  };
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token: string;
  // Customizable Area Start
  emailAttachements: any;
  loading: boolean;
  emailPopup: boolean;
  surveyData: any;
  allQuestion: any;
  deleteTemplteId: any;
  deleteQuestion: boolean;
  deleteId: number;
  emailData: any;
  subject: string;
  content: string;
  emailErrorMsg: string;
  sendTo: any[];
  newEmail: string;
  modal: string;
  anchorElForPopup: HTMLButtonElement | null;
  filterText : string;
  AnchorEl: null | HTMLElement;
  quarterValue: QuarterAttributes[]
  isEditSequence: boolean;
  newSequenceQuestions: any;
  resultQueData:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getServeyDetailCallId = '';
  getAllQuestionId = '';
  getAllScoringQuestionId='';
  questionDeleteCallId = '';
  templateDeleteCallId = '';
  sendEmailCallId = '';
  downloadReportCallId = '';
  tokenData:any;
  createQuarterCallId = '';
  getQuarterDataCallId='';
  uniqueSessionRequesterId:string= '';
  authToken:string= '';
  newSequenceQuestions:any = '';
  reportingFrameworkTitle: string = '';
  
  requestSessionData() {
    const sessionMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = sessionMsg.messageId;
    this.send(sessionMsg);
    return true;
  }

  async componentDidMount() {
    super.componentDidMount();
    this.tokenData= await getStorageData("authToken") || ''
    const authToken = await getStorageData("authToken");
    this.reportingFrameworkTitle = await getStorageData("reportingFrameworkTitle");
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    }
    this.requestSessionData()
    this.onInitialized();
  }

  onInitialized = async() => {
    const token= await getStorageData("authToken") || ''
    const survey_id = this.props.navigation.getParam('survey_id')
    this.getServeyDetailCallId = sendAPIRequest(configJSON.getSurveyByIdEndpoint.replace(':sid', survey_id), {
      method: 'GET',
      headers: { token }
    })  
  }

  getQuarterData(survey_id: string){
    const token= this.authToken
    this.getQuarterDataCallId = sendAPIRequest(configJSON.getQuarterData.replace(':sid', survey_id), {
      method: 'GET',
      headers: { token }
    })
  }

  getAllQuestion = async() => {
    const token= await getStorageData("authToken") || ''
    const survey_id = this.props.navigation.getParam('survey_id')
    this.getAllQuestionId = sendAPIRequest(`bx_block_surveys/surveys/${survey_id}/survey_questions`, {
      method: 'GET',
      headers: {token},
    })
  }
  getAllScoringQuestion = async() => {
    const token=  await getStorageData("authToken") ?? ''
    const survey_id = this.props.navigation.getParam('survey_id')
    this.getAllScoringQuestionId = sendAPIRequest(configJSON.getScoringQuestionByIdEndpoint.replace(':sid', survey_id), {
      method: 'GET',
      headers: {token},
    })
  }

  createQuarter = () => {
    const token= this.authToken ?? ''
    const survey_id = this.props.navigation.getParam('survey_id')
    this.createQuarterCallId = sendAPIRequest(configJSON.createQuarterEndPoint.replace(':sid', survey_id), {
      method: 'GET',
      headers: {token},
    })
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if ((prevProps.navigation.history.location.state?.surveyId && prevProps.navigation.history.location.state?.surveyId !== this.props.navigation.getParam('survey_id')) || (this.props.navigation.history.action === "REPLACE" && this.props.navigation.history.location.state?.changeQuestion)) {
      this.setState({loading:true})
      this.onInitialized();
      this.props.navigation.history.replace({ state: {surveyId:this.props.navigation.getParam('survey_id'),changeQuestion:false} })
    }
  }

  handleCoinsMessage = (data: any) => {
    const corpstageWallet = data
    this.setState({ allQuestion: {...this.state?.allQuestion, data} })
  }
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
  
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage), 
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];
    
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token: '',
      // Customizable Area Start
      emailAttachements: [],
      loading: true,
      emailPopup: false,
      surveyData: {},
      allQuestion: [],
      deleteTemplteId: '',
      deleteQuestion : false,
      deleteId: 0,
      emailData: {},
      subject: 'Share your valuable thoughts with us',
      content: ``,
      emailErrorMsg: "",
      sendTo: [],
      newEmail: '',
      modal: '',
      anchorElForPopup: null,
      filterText:'',
      AnchorEl: null,
      quarterValue:[],
      isEditSequence: false,
      newSequenceQuestions: {},
      resultQueData:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area Start
    // Customizable Area End
    
  }

  async receive(from: string, message: Message) {
    
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      
      this.setState({ txtSavedValue: value });
    }
    
    this.handleSessionResponse(message, this.uniqueSessionRequesterId);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
        
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
          let errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson && responseJson.errors || errorReponse) {
              // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if(responseJson) {
        switch (apiRequestCallId) {
          case this.getServeyDetailCallId:
            this.handleSurveyDetailApiResponse(responseJson)
            break;
          case this.getAllQuestionId:
            const allQuestion = { ...responseJson.questions };
            this.setState({ allQuestion }, () => {
              this.getAllScoringQuestion();
            });
            break;
          case this.getAllScoringQuestionId:
            this.handleScorringQuestionType(responseJson)
            break;
          case this.questionDeleteCallId:
            this.getAllQuestion()
            toast.success(responseJson.message);
            break;
          case this.templateDeleteCallId:
            this.setState({ deleteTemplteId: undefined });
            this.props.navigation.goBack();
            break;
          case this.sendEmailCallId:
            toast.success(responseJson?.message);
            this.closeEmailPopup();
            this.setState({ sendTo: [], loading: false });
            break;
          case this.downloadReportCallId:
            this.handleDownloadReport(responseJson);
            break;
          case this.getQuarterDataCallId:
            this.handleQuarterData(responseJson);
            break;
          case this.createQuarterCallId:
            toast.info(responseJson.message ?? "Quarter Created Successfully");
            this.getQuarterData(this.props.navigation.getParam('survey_id'))
            break;
          case this.newSequenceQuestions:
            toast.success(responseJson.message);
            break;
          default:
            break;
        }
        
      }
    }
    parseNewQuestionMessage(message, this.handleCoinsMessage)
    // Customizable Area End
  }
  
  // Customizable Area Start

  
  handleSessionResponse = async(message:Message, uniqueSessionRequesterId:string) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const requesterId = message.getData(getName(MessageEnum.SessionRequestedBy));
      if (requesterId === uniqueSessionRequesterId) {
        const sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));
        this.authToken = sessionToken ?? await getStorageData("authToken");
      }
    }
  }

  handleSurveyDetailApiResponse(responseJson:any):void {
    const { data } = responseJson;
    const surveyData = {
      ...data?.attributes,
      id: data?.id
    };
  
    this.setState({ surveyData }, () => {
      if (data?.attributes.mandatory) {
        this.getQuarterData(data?.id);
      }
      this.getAllQuestion();
    });
  }
  

  handleDownloadReport = (responseJson:{download_url_Link:string}) => {
    if (responseJson.download_url_Link == null) {     
          toast.warn("Report is not generated");
        }
        else {
          window.open(responseJson.download_url_Link);
        }
  }
  handleAttachement = (event:any) => {
    const newAttachments = Array.from(event.target.files);
    this.setState((prevState) => ({
      emailAttachements: [...prevState.emailAttachements, ...newAttachments],
    }));
  }

  handleQuarterData = (quarterData:QuarterData) => {

    const extractedAttributes = quarterData.data.map((item:QuarterDetail) => 
    ({
      ...item.attributes,
      survey_id: item.id
    }));
    this.setState({
      quarterValue:extractedAttributes
    });
  }

  deleteAttachement= (index: number) => {
    const emailAttache = this.state.emailAttachements
    emailAttache.splice(index, 1);
    this.setState({emailAttachements: emailAttache})
  }

  handleEditTemplate = (id:any) => () =>{
    
    const url = `${this.props.navigation.history.location.pathname}/EditSurveys/${id}`
    this.props.navigation.history.push(url)
  }

  emailValue = (e:any) => {
    this.setState({newEmail: e.target.value})
  }

  validateEmail = (email:any) => {
    if(email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )){
        this.setState({emailErrorMsg: ""})
        return true
      }
      toast.warn("Email is not valid");
      this.setState({emailErrorMsg: "Email is not valid"})
      return false
  };

  addEmail = (e:any) => {
    if(e.keyCode == 13){
      if(this.validateEmail(this.state?.newEmail)){
        this.setState({sendTo: [...this.state?.sendTo, this.state?.newEmail]})
        this.setState({newEmail: ""})
      }
    }
  }

  deleteEmail = (index:number) => {
    const sendTo = this.state?.sendTo.filter((x:any)=> x != index)
    this.setState({sendTo})
  }

  emailValidation = () => {
    let isValid = true
    if(this.state?.subject == "" || this.state?.subject == null){
      this.setState({emailErrorMsg: "Subject is mandatory"})
      toast.warn("Subject is mandatory")
      isValid = false;
    }
    if(this.state?.content == "" || this.state?.content == null){
      this.setState({emailErrorMsg: "Content is Mandatory"})
      toast.warn("Content is Mandatory")
      isValid = false;
    }
    if(this.state?.newEmail?.length < 1 && this.state?.sendTo?.length == 0) {
      this.setState({emailErrorMsg: "To Field is Mandatory"})
      toast.warn("To Field is Mandatory")
      isValid = false;
    }
    return isValid;
  }

  handleSendEmail = async() => {
    let valid = this.emailValidation();
    if(this.state?.sendTo?.length == 0 && this.state?.newEmail?.length > 0){
      toast.warn("press enter to add email")
      valid = false;
    }
    else{
      const id = this.props.navigation.getParam('survey_id')
      const body = new FormData();
      
      body.append('id', id);
      body.append('link', `${window?.location?.origin}/SurveyEnduser/${id || "0"}`);
      this.state?.sendTo?.map((email, index: number) => {
        body.append(`account_email[${index}]`, email);
        if(Array.isArray(this.state.emailAttachements)){ 
          this.state.emailAttachements.forEach((im: any) => body.append('attachments[]', im))} 
      })
      const token= await getStorageData("authToken") || ''
      if(valid){
      this.setState({loading:true})
      this.sendEmailCallId = sendAPIRequest(configJSON.senEmailEndpoint, {
        method: 'POST',
        headers: {token},
        body,
      })
    }
  }
  }

  downloadReport = async(fileType:any) => {
    const token= await getStorageData("authToken") || ''
    const id = this.state?.surveyData?.id
    this.downloadReportCallId = sendAPIRequest(`${configJSON.downloadReportEndpoint}/${id}/download_report_surveyer`,{
      method: 'GET',
      headers: {token},
    })
    }

  changeSubject = (e:any) => {
    this.setState({subject: e.target.value})
  }

  changeContent = (e:any) => {
    this.setState({content: e.target.value})
  }

  onDeleteQuestion = (id:number) => async() => {
    let tempObj = this.state?.allQuestion?.data?.find((item: any) => item.id == id);
    const token= await getStorageData("authToken") || ''
    const survey_id = this.props.navigation.getParam('survey_id')
    this.questionDeleteCallId = sendAPIRequest(`${tempObj.attributes?.response_type_id === 4 ?configJSON.getScoringSurveyByIdEndpoint.replace(':sid', survey_id):configJSON.getSurveyByIdEndpoint.replace(':sid', survey_id)}/survey_questions/${id}`, {
      method: 'DELETE',
      headers: { token }
    })
    const ReemainQuestion = this.state?.allQuestion?.data?.filter((question:any)=> question.id != id);
    this.setState({allQuestion: {data: ReemainQuestion}, deleteQuestion: false})
    // this.setState()
  }

  deleteQuestion = (id: number) => () => {
    this.setState({deleteId: id});
    this.setState({deleteQuestion: true})
  }

  onEditQuestion = (id:number,sequenceNo:number) => () => {
    const questionData = this.state?.allQuestion?.data.find((question:any) => question.id == id);
    if(!questionData.attributes.childQue) {
    this.props.navigation.history.push({pathname:`EditSurveyQuestion/${id}`, question_id: id, state: this.state?.surveyData?.id, survey_id: this.props.navigation.getParam('survey_id') ,sequence_no: sequenceNo, allQuestion:this.state?.allQuestion?.data})
    }else{
      toast.warn("Parent Question cannot be edited until child questions are deleted.");
    }
  }

  onBack = () => {
    this.props.navigation.navigate('Surveys')
  }

  closeDeleteModal = () => {
    this.setState({deleteTemplteId: undefined})
  }

  closeModal = () => {
    this.setState({deleteQuestion: false})
  }

  handleDeletePopup = (data:any) => () => {
    if(data?.status == "live"){
      toast.warn("Can not delete Live Template");
    }
    else{
      this.setState({deleteTemplteId: data?.id})
    }
  }

  handleDeleteTemplate = (id:any) => async() => {
    const token= await getStorageData("authToken") || ''
    this.templateDeleteCallId = sendAPIRequest(configJSON.deleteSurveyTemplate.replace(':id', id), {
      method: 'DELETE',
      headers: { token }
    })
    this.setState({deleteTemplteId:id})
  }


 findHighestSequenceNo(questions:any) {
  return new Promise((resolve) => {
    let highestSequenceNo = -1;
    questions.forEach((question:any) => {
      const sequenceNo = question.attributes.sequence_no;
      if (sequenceNo > highestSequenceNo) {
        highestSequenceNo = sequenceNo;
      }
    });

    if (highestSequenceNo >= 1) {
      resolve(highestSequenceNo+1);
    } else {
      resolve(1);
    }
  });
}

  onQuestionAdd = async() => {
    const sNo = await this.findHighestSequenceNo(this.state?.allQuestion?.data)
    this.props.navigation.history.push({ pathname: `AddSurveyQuestions`, state: this.state?.surveyData?.id, survey_id: this.props.navigation.getParam('survey_id'), sequence_no: sNo, allQuestion: this.state?.allQuestion?.data })
  }

  onChildQuestionAdd = async(parentQuestion:any) => {
    const sNo = await this.findHighestSequenceNo(this.state?.allQuestion?.data)
    this.props.navigation.history.push({ pathname: `AddSurveyQuestions`, state: this.state?.surveyData?.id, survey_id: this.props.navigation.getParam('survey_id'), sequence_no: sNo, allQuestion: this.state?.allQuestion?.data, parentQuestion:parentQuestion })
  }

  openEmailPopup = () => {
    this.setState({
      emailPopup:true,
      content: `As an open and transparent company committed to sustainability, we truly value feedback of our stakeholders. Please share with us your experience of dealing and engaging  with our company by taking part in this short survey.
      About the survey:
      To access the survey, click on this link.
      It will take approximately ${this.state?.surveyData?.survey_time ? this.state?.surveyData?.survey_time : 0} minutes to complete this survey. Please provide your response by ${this.state?.surveyData?.end_date}.
      
      Thanks
      Company Stakeholders engagement  Team`
    })
  }

  closeEmailPopup = () => {
    this.setState({emailPopup: false, sendTo: [], newEmail: '', emailAttachements: []})
  }

  handleShareClick  = (s: any) => () => {
    let data = {
      userId: s?.created_by?.id,
      postId: s?.id,
      link: `${window?.location?.origin}/SurveysDetails/${s?.id || "0"}`,
      shareableType: 'BxBlockSurveys::Survey',
      shareableId: s?.id,
    }
    sendSharePopupMessage(data);
  }

  handleScorringQuestionType = (responseJson:any) => {
    const allScoringQuestion = { ...responseJson?.questions };
            const scoringQuestions = allScoringQuestion?.data?.map((question:any) => {
              const queObj = question.attributes;
              queObj["response_type"] = "scoring";
              queObj["response_type_id"] = 4;
              return question;
            });
            const checkSequence = scoringQuestions?.every((obj:any) => typeof obj.sequence_no === 'number');
            const allQuestions = checkSequence
              ? scoringQuestions?.sort((a:any, b:any) => a.sequence_no - b.sequence_no)
              : scoringQuestions?.sort((a:any, b:any) => a.id - b.id);
            const children = this.state.allQuestion.data?.concat(allQuestions);
            const checkSequenceQuestionData = children.every((obj:any) => typeof obj.attributes.sequence_no === 'number');
            const sortedArray = checkSequenceQuestionData
              ? children.sort((a:any, b:any) => a.attributes.sequence_no - b.attributes.sequence_no)
              : children.sort((a:any, b:any) => a.id.localeCompare(b.id));

              const resultData = this.addChildQuestions(sortedArray);
    console.log("renderQuestion++++2=3",resultData,sortedArray);

            this.setState({ allQuestion: { data: sortedArray },resultQueData:resultData, loading: false });
  }
  fileIcon = (fileType: string, filename: string) => {
    const extension = filename.split(".").pop();
    const imageType = fileType.split('/')[0];
    if (imageType === 'image') {
        return imageIcon;
    } else if (imageType === 'video') {
        return videoIcon;
    } else if (imageType === 'text' || extension === 'csv') {
        return csvIcon;
    } else if (imageType === "application") {
        if (extension === 'pdf') {
            return pdfIcon;
        } else if (extension === 'doc' || extension === 'docx') {
            return docIcon;
        } else if (extension === 'xls' || extension === 'xlsx') {
            return xlsIcon;
        }
    } else {
        return genericIcon;
    }
}
onDownload = (index: number,file:any) => {
  const downloadFile:any= file
  if (downloadFile instanceof File) {
      const fileBlob = new Blob([downloadFile], { type: downloadFile.type });
      const fileUrl = URL.createObjectURL(fileBlob);
      this.openUrl(fileUrl)
      URL.revokeObjectURL(fileUrl);
    } else {
      this.openUrl(downloadFile?.url)
    }
}
 openUrl = (fileUrl:string) => {
  const newTab = window.open((fileUrl), "_blank");
  if (newTab) {
    newTab.focus();
  } else {
      toast.error("Failed to open file in a new tab.")
  }
}

isUpdateAllowed = (status:string,startDate:string,mandatory:boolean,end_date:string) =>{
  const currentDate = moment();
  const surevyStartDate = moment(startDate, "DD/MM/YYYY");
  const surevyEndDate = moment(end_date,"DD/MM/YYYY");
  if(mandatory){
    const allowed = currentDate.isBefore(surevyEndDate) && !currentDate.isAfter(surevyStartDate)
    console.log("allowed+++", allowed,currentDate.isBefore(surevyEndDate),!currentDate.isAfter(surevyStartDate));
    
    return allowed
  }else if (!mandatory){
    return status === "active";
  }

}

updatedAttachments = (givenData:any) => {
  const host = configJSON.baseURLString
  const updatedAttachments = givenData?.map((attachment:Attachment) => {
      if (attachment.url) {
        return { ...attachment, url: host + attachment.url };
      }
      return attachment;
    });
    return updatedAttachments
}

handleFilterMenuOpen = (e: any) => {
  this.setState({
    AnchorEl: e.currentTarget
  })
}
handleClose = () => {
  this.setState({
    AnchorEl: null
  })
}
onQuarterSelect = async(surveyId:string, filterText: string) => {
  this.setState({AnchorEl: null,filterText})
  this.props.navigation.history.push(`/SurveysDetails/${surveyId}`,{surveyId:this.props.navigation.getParam('survey_id')});
}

snakeCaseToTitleCase(inputString:string) {
  return inputString
    .replace(/_/g, ' ')                // Replace underscores with spaces
    .replace(/\b\w/g, match => match.toUpperCase());  // Capitalize the first letter of each word
}

sortQuestions = (questions: any, sortBySequence: any) => {
  if (sortBySequence) {
    return questions.slice().sort((a: any, b: any) => a.attributes.sequence_no - b.attributes.sequence_no);
  } else {
    return questions.slice().sort((a: any, b: any) => a.id.localeCompare(b.id));
  }
};

handleUpButtonClick = (index: number, isChild: boolean, parentId?: string) => {
  this.setState((prevState) => {
    const allQuestionCopy = [...prevState.resultQueData];
    let currentArray;
    let currentQuestionIndex = index
    if (isChild && parentId) {
      const parentQuestionObj = allQuestionCopy.find((question) => question.id === parentId);

      if (parentQuestionObj && parentQuestionObj.attributes.childQue) {
        currentArray = parentQuestionObj.attributes.childQue;

      } else {
        return null;
      }
    } else {
      currentArray = allQuestionCopy;
    }

    if (currentQuestionIndex > 0) {
      const temp = currentArray[currentQuestionIndex].attributes.sequence_no;
      currentArray[currentQuestionIndex].attributes.sequence_no = currentArray[currentQuestionIndex - 1].attributes.sequence_no;
      currentArray[currentQuestionIndex - 1].attributes.sequence_no = temp;

      const checkSequenceQuestion = currentArray.every((obj: any) => typeof obj.attributes.sequence_no === 'number');

      const sortedQuestionsData = this.sortQuestions(currentArray, checkSequenceQuestion);

      if (isChild && parentId) {
        const updatedParent = allQuestionCopy.find((question) => question.id === parentId);
        if (updatedParent) updatedParent.attributes.childQue = sortedQuestionsData;
      }
      else {allQuestionCopy.splice(0, currentArray.length, ...sortedQuestionsData)}

      return { resultQueData: allQuestionCopy};
    }

    return null;
  });
};

handleDownButtonClick = (index: number, isChild: boolean, parentId?: string) => {
  this.setState((prevState) => {
    const allQuestionCopy = [...prevState.resultQueData];
    let currentArray;
    let childWithParent = isChild && parentId

    if (childWithParent) {
      const parentQuestion = allQuestionCopy.find((question) => question.id === parentId);

      if (parentQuestion && parentQuestion.attributes.childQue) {
        currentArray = parentQuestion.attributes.childQue;
      } else {
        return null;
      }
    } else {
      currentArray = allQuestionCopy;
    }

    if (index >= 0 && index < currentArray.length - 1) {
      const temp = currentArray[index].attributes.sequence_no;
      currentArray[index].attributes.sequence_no = currentArray[index + 1].attributes.sequence_no;
      currentArray[index + 1].attributes.sequence_no = temp;

      const checkSequenceQuestionData = currentArray.every(
        (obj: any) => typeof obj.attributes.sequence_no === 'number'
      );

      const sortedArray = this.sortQuestions(currentArray, checkSequenceQuestionData);

      if (childWithParent) {
        const updatedParent = allQuestionCopy.find((question) => question.id === parentId);
        if (updatedParent) {
          updatedParent.attributes.childQue = sortedArray;
        }
      } else {
        allQuestionCopy.splice(0, currentArray.length, ...sortedArray);
      }

      return { resultQueData: allQuestionCopy };
    }

    return null;
  });
};


updateSequenceApiCall = async() => {
  const token= await getStorageData("authToken")
  const survey_id = this.props.navigation.getParam('survey_id')
  this.newSequenceQuestions = sendAPIRequest(configJSON.saveNewSequenceQuestions.replace(':id', survey_id), {
    method: 'POST',
    headers: {
      token,
      'Content-Type': 'application/json',
    },
    body: {
      questions: this.state.newSequenceQuestions.questions
    },
  })
}

saveNewSequence = () => {
  const transformedQuestions = this.state.allQuestion.data.map((question:any )=> {
    const responseType = question.attributes.response_type === 'scoring' ? '' : question.attributes.response_type;
    return {
      "question_id": parseInt(question.id),
      "sequence_no": question.attributes.sequence_no,
      "response_type": responseType
    };
  });

  this.setState({ isEditSequence: !this.state.isEditSequence, newSequenceQuestions: { questions: transformedQuestions}}, () => {
    this.updateSequenceApiCall();
  })
}

addChildQuestions(data: SurveyQuestion[]): SurveyQuestion[] {
  const questionMap: Record<string, SurveyQuestion> = {};

  data.forEach((question) => {
    questionMap[question.id] = question;
  });

  data.forEach((question) => {
    if (question.attributes.parent) {
      const parentId = question.attributes.parent.id;
      if (questionMap[parentId]) {
        if (!questionMap[parentId].attributes.childQue) {
          questionMap[parentId].attributes.childQue = [];
        }

        const childQuestion = { ...question };
        const index = questionMap[parentId].attributes.childQue!.findIndex(
          (child) => child.id === childQuestion.id
        );

        if (index === -1) {
          questionMap[parentId].attributes.childQue!.push(childQuestion);
        }
      }
    }
  });

  Object.values(questionMap).forEach((question) => {
    if (question.attributes.childQue) {
      const checkSequenceQuestionData = question.attributes.childQue.every(
        (obj) => typeof obj.attributes.sequence_no === 'number'
      );

      question.attributes.childQue.sort((a, b) =>
        checkSequenceQuestionData
          ? a.attributes.sequence_no - b.attributes.sequence_no
          : a.id.localeCompare(b.id)
      );
    }
  });

  const resultData: SurveyQuestion[] = data.filter(
    (question) => !question.attributes.parent
  );

  return resultData;
}

getIndexLabel = (index: number, isChild: boolean,resultData:SurveyQuestion[], parentId?: string ): string => {
  const parentQuestionId = isChild ? parentId : undefined;
  if (!isChild) {
    return `${index + 1}.`;
  } else if (parentQuestionId) {
    const parentIndex = resultData.findIndex((question: any) => question.id === parentQuestionId);    
    if (parentIndex !== -1) {
      return `${parentIndex + 1}.${index + 1}`;
    }
  }
  return `${index + 1}.`;
};
getNavigationMessage = (route: string, props: any, payload?: any) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), route);

  message.addData(getName(MessageEnum.NavigationPropsMessage), props);

  this.send(message);
}

checkResponseType(data:any):boolean {
  if (data && data.attributes && data.attributes.response_type_id) {
    const responseType = data.attributes.response_type_id;
    const parent = data.attributes.parent;
    return (responseType === 1 || responseType === 2 || responseType === 4) && (!parent || Object.keys(parent).length === 0);
}
  return false;
}
getQuestionList(state:any) {
  if (!state.allQuestion?.data || state.allQuestion.data.length === 0) {
    return true;
  } else {
    return false;
  }
}
getTagedUserCondition(surveyData:any) {
  return surveyData?.data && surveyData.data.length > 0
}
getQuestionsBoxMinHeight(length:any){
  return length < 1 ? '250px' : 'auto'
}
  // Customizable Area End
  
}
