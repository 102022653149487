import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { sendAPIRequest } from '../../../../components/src/utils'
import { toast } from 'react-toastify';
import { getStorageData } from "../../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  reportingData: any;
  allPolicies: any;
  deletePoicy: boolean;
  deletePolicyId: number;
  modal: string;
  editPolicyData: any;
  uploadedAttachement : any[];
  deleteAttachment: any[];
  loading: boolean;
  isdisabled: boolean;
  filesArray: any[],
  removedAttachments: number[]
  
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BaselineReportingController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getAllPoliciesCallId: string = '';
  deletePolicyCallId: string = '';
  createPolicyId:string = '';
  showPolicyId:string = '';
  editPolicyId:string = '';
  deleteAttachementId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      reportingData: "",
      allPolicies: [],
      deletePoicy: false,
      deletePolicyId: 0,
      modal: '',
      editPolicyData: null,
      uploadedAttachement: [],
      deleteAttachment: [],
      loading: false,
      isdisabled: false,
      filesArray: [],
      removedAttachments: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    console.log("check states", this.props.navigation.history.location)
    const authToken= await getStorageData('authToken');

    if (authToken == null) {
        this.props.navigation.navigate('Home')
    } else {
            const access = this.props?.navigation?.history?.location?.state?.ability_type
            console.log("access rights =", access);
            if (access == "read") {
              this.setState({ isdisabled: true })
            }
             this.getAllPoliciesCallId = sendAPIRequest(configJSON.getAllPolicies, {
                method: 'GET',
                headers: {'token': authToken},
             })
    }
    // Customizable Area End
}


  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
          this.parseApiErrorResponse(responseJson.errors);
          this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
          if(apiRequestCallId == this.getAllPoliciesCallId) {
              this.setState({allPolicies: responseJson?.policies?.data});
          } else if(apiRequestCallId == this.deletePolicyCallId) {
            const allPolicies = this.state.allPolicies.filter((data:any) => data.id != this.state.deletePolicyId)
            this.setState({deletePoicy: false, allPolicies})
            toast.success("Deleted Successfully ");
          } else if(apiRequestCallId == this.createPolicyId) {
            this.state.allPolicies.push(responseJson.data);
            this.setState({modal:'', loading: false})
            toast.success("Policy Added Successfully ");
          } else if(apiRequestCallId == this.showPolicyId) {
            this.setState({editPolicyData : responseJson.data});
            this.setState({modal: 'create', uploadedAttachement: responseJson.data.attributes?.policy_documents});
          } else if(apiRequestCallId == this.editPolicyId) {
            this.state.allPolicies.map((data:any)=> {
              if(data.id == responseJson.data.id){
                data.attributes = responseJson.data.attributes
              }
            })
            this.setState({modal:'', editPolicyData: null, loading: false})
            toast.success("Policy Updated Successfully ");
          }
      }
  }
    // Customizable Area End

  }
  
 // Customizable Area Start
  validationSchema = yup.object().shape({
    policy_name: yup.string().required("Policy name is required"),
    description: yup.string().label('Description').required("Description is required").max(1000),
    attachments: yup.array().min(1).required("Attachement is required"),
  })

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry

    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry

  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  

  setFilesArray = (file: any) => {
    this.setState({ filesArray: [file] });
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  // Customizable Area 

  createData(
    SlNo: number,
    Policy_Name: string,
    Description: string,
    Attachments: string,
    Created_By: string,
    Last_Updated: string,
  ) {
    return { SlNo, Policy_Name, Description, Attachments, Created_By, Last_Updated };
  }

  handleEditTemplate = (id: any) => async() => {
    if(this.state?.isdisabled) {
      toast.warn("You are not able to perform this action")
      return
    }
    const authToken= await getStorageData('authToken');
    this.showPolicyId = sendAPIRequest(configJSON.showPolicy.replace(':id', id), {
      method: 'GET',
      headers: {'token': authToken}
    })
  }

  deletePolicy = (id: number) => () => {
    if(this.state?.isdisabled) {
      toast.warn("You are not able to perform this action")
      return
    }
    this.setState({deletePoicy: true})
    this.setState({deletePolicyId: id});
  }

  onDeletePolicy = (id:number) => async() => {
    console.log("allQuestion delete1 ");
    const authToken= await getStorageData('authToken');

    this.deletePolicyCallId = sendAPIRequest(`${configJSON.detePolicy.replace(':id', id)}`, {
      method: 'DELETE',
      headers: { 'token': authToken }
    })
  }

  closeModal = () => {
    this.setState({deletePoicy: false, editPolicyData: null})
  }

  hideModal = () => {
    this.setState({ modal: '', editPolicyData: null })
  }

  openModal = () => {
    this.setState({ modal: 'create' })
  }

  removeAttachments = (attachmentId:number) => {
    console.log(attachmentId, "!!!!!!!!!AID")
    if(attachmentId){
        this.setState(prevState => ({
            removedAttachments: [...prevState.removedAttachments, attachmentId]
        }))
    }
}

  handleCreateFormSubmit = async (data:any) => {
    this.setState({loading: true})
    const authToken= await getStorageData('authToken');
    const body = new FormData();
    let attch = false
    body.append('policy[name]', `${data.policy_name}`)
    body.append('policy[description]', `${data.description}`)
    data.attachments.forEach((file: any) => {if(!file?.id){body.append('policy_documents[]', file)}})

    
    if(this.state?.editPolicyData != null){
      if(this.state.uploadedAttachement.length != 0 || attch){
      
      const token = await getStorageData('authToken');


      if (this.state.removedAttachments.length > 0) {
        const removeAttachmentUrl =  `${configJSON.deletePolicyAttachmentEndPoint + this.state.editPolicyData.id + '/remove_selected_document'}` 
        const removeAttachmentBody = {document_id: this.state.removedAttachments };
        console.log(removeAttachmentUrl, "~~~~~~~~~~url")
        const removeAttachmentPromise = new Promise<void>((resolve, reject) => {
          sendAPIRequest(removeAttachmentUrl, {
            method: 'POST',
            headers: { token,'content-type': 'application/json' },
            body: removeAttachmentBody,
          });
          resolve();
        });
        await removeAttachmentPromise;
        this.setState({removedAttachments: []})
      }

    setTimeout(()=> {
      this.editPolicyId = sendAPIRequest(configJSON.showPolicy.replace(':id', this.state.editPolicyData.id), {
        method: 'PUT',
        headers: { 'token': authToken },
        body
      })
    }, 1000)
    }
    else{
      toast.warn("Atleast one attachement is required")
      this.setState({loading: false})
    }
    }
    else{
      this.createPolicyId = sendAPIRequest(configJSON.createPolicy, {
        method: 'POST',
        headers: { 'token': authToken },
        body
      })
    }

  }
  // Customizable Area End

}
