import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendAPIRequest } from '../../../components/src/utils';
import { getStorageData } from '../../../framework/src/Utilities';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  initCall?: string;
  openCorporateGovernance?: boolean;
  onCloseCorporateGOvernance?: any;
  isEditCoporateGovernance?: boolean;
  isDisabled?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  engagementData:string;
  loading: boolean;
  isLoading: boolean;
  cv: any;
  showChip:boolean;
  agendaOfFirstBoardMeetings:any,
  agendaOfSecondBoardMeetings:any,
  agendaOfThirdBoardMeetings:any,
  loadingBoardOperation:boolean;
  boardOperationData: {
    id: any;
    attributes: {
      agenda_of_last_three_board_meetings: string;
      minutes_of_last_three_board_meetings: string;
      board_committee: string;
      reference_of_audit_committees: string;
      head_of_internal_auditor: string;
      internal_auditor_reporting_to: string;
      partner_engaged_names: string;
      statutory_auditor_consulting_company_name: string;
      terms_of_engagement_of_statutory_auditors: string;
      auditor_fees_amount: string;
      upload_minutes_of_last_three_board_meetings: any[];
      upload_reference_of_audit_committees: any[];
      upload_reference_of_remuneration_committees: any[];
      upload_reference_of_nomination_committees: any[];
      fees_determination: any[];
      upload_agenda: any[];
      review: any[];
    }
  };
  removedAttachments:any[];
  showcorporateForm: boolean;
  filesArray: any[];
  boardMeetingsAttachements: any[];
  referenceOfAuditCommittees: any[];
  remunirationCommittees: any[];
  nominationCommittees: any[];
  determinationResolution: any[];
  statutoryAuditors: any[];
  profilePic: any[];
  imageUrl:string | null;
  handleLoading: boolean;
  pages: number;
  filesSelected: any[];
  total_pages: number;
  total_count: number;
  current_page: number;
  txtSavedValue: string;
  enableField: boolean;
  addNewProfile: boolean;
  tableRowId: any;
  editProfile: boolean;
  isEditGovernance: boolean;
  token: string;
  industryExperience:any[];
  filterMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  filterRequestTypes: any[];
  filterRequestType?: string;
  filterCreatedByMe: boolean;
  filterStatus: string;
  selectingDate?: boolean;
  serveyTemplates: any;
  surveyId: any;
  deleteTemplteId: any;
  urlSearchParam: string;
  filterQuery: any;
  loadingSurveyTemplates: boolean;
  queryStr: string;
  pagination?: {
    next_page?: number;
  };
  filterByMe: boolean;
  filterByFav: boolean;
  communities: any[];
  requestors: any[];
  filterCommunities: any[];
  governance_current_page: number;
  governance_total_pages: number;
  filterRequestors: any[];
  filterByStatus: any;
  deleteId: any;
  deleteModal: boolean;
  notFound: string;
  activeSubscribtionHistoryButton: boolean;
  activeChooseButton: boolean;
  subscribtionPlan: boolean;
  subscribtionHistory: boolean;
  reportingData: any;
  // openStackholderForm:boolean;
  surveyData: any;
  agendaOfLastThreeMeeting: any;
  deleteQuestion: boolean;
  allQuestion: any;
  stackholderProfileList: any;
  stackholderProfilePagination: any;
  modalOpen: string;
  delete: boolean;
  deleteCorporateGovernanace: boolean;
  corporateGovernanceData: any[];
  corporateForm: boolean;
  KeyStackHolder: any[];
  showEngagementApproachOthersField: boolean;
  showFrequencyTimelineOthersField: boolean;
  EngagementApproach: any;
  EngagementTools: any[];
  FrequencyTimeline: any[];
  stakeholderManagerData:any[];
  selectedStakeHolderManager:any;
  stackholderPagination?: any;
  level: string;
  stackholderSearchCallId: string,
  stackholderQuery: any,
  stackholderPaginateCallId?: any,
  allStackholderOptions: any[],
  disabled: boolean,
  profileCompletionCheck: boolean,
  selfAssessmentCheck: boolean,

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StakeholderProfileFormController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  filterRef?: any;
  surveyTemplateCallId: string = '';
  templateDeleteCallId: string = "";
  templatesFilterCallId: string = "";
  apiPaginateTemplatesCallId: string = "";
  taggedCommunitiesCallId: string = "";
  checkStatusCallId: string = "";
  filterSurveyTemplateCallId: string = "";
  requestorsCallId: string = "";
  questionDeleteCallId = '';
  getStackholderProfileId = "";
  deleteStackholderProfileId = "";
  deleteGovernanceProfileId = "";
  getCorporateGovernanceList = "";
  addCoporateData: any;
  editCoporateData: any;
  createdBoardOperations: any;
  updatedBoardOperations: any;
  boardOperations: any;
  deletedBoardOperations: any;
  deletedCV:any;
  editedStakeHolderProfileForms: any;
  addedStakeHolderProfileForms: any;
  stakeholderManagerList:any;
  approverCallId: string = '';
  initApproversPagination:any=undefined;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      cv:[],
      selectedStakeHolderManager:"",
      showChip:true,
      industryExperience:[],
      agendaOfFirstBoardMeetings:null,
      agendaOfSecondBoardMeetings:null,
      agendaOfThirdBoardMeetings:null,
      loadingBoardOperation:false,
      engagementData:"",
      corporateGovernanceData: [],
      handleLoading: false,
      loading: false,
      removedAttachments:[],
      showEngagementApproachOthersField: false,
      showFrequencyTimelineOthersField: false,
      KeyStackHolder: [],
      isEditGovernance: false,
      EngagementApproach: [],
      EngagementTools: [],
      FrequencyTimeline: [],
      agendaOfLastThreeMeeting:"",
      boardOperationData: {
        id: null,
        attributes: {
          agenda_of_last_three_board_meetings: '',
          minutes_of_last_three_board_meetings: '',
          board_committee: '',
          reference_of_audit_committees: '',
          head_of_internal_auditor: '',
          internal_auditor_reporting_to: '',
          partner_engaged_names: '',
          statutory_auditor_consulting_company_name: '',
          terms_of_engagement_of_statutory_auditors: '',
          auditor_fees_amount: '',
          upload_minutes_of_last_three_board_meetings: [],
          upload_reference_of_audit_committees: [],
          upload_reference_of_remuneration_committees: [],
          upload_reference_of_nomination_committees: [],
          fees_determination: [],
          upload_agenda: [],
          review: []
        }
      },
      showcorporateForm: false,
      filesSelected: [],
      filesArray: [],
      isLoading: false,
      boardMeetingsAttachements: [],
      referenceOfAuditCommittees: [],
      remunirationCommittees: [],
      nominationCommittees: [],
      determinationResolution: [],
      statutoryAuditors: [],
      profilePic: [],
      imageUrl:'',
      corporateForm: false,
      delete: false,
      deleteCorporateGovernanace: false,
      pages: 1,
      governance_current_page: 1,
      governance_total_pages: 1,
      total_count: 0,
      total_pages: 1,
      current_page: 1,
      txtInputValue: "",
      txtSavedValue: "A",
      addNewProfile: false,
      tableRowId: '',
      editProfile: false,
      enableField: false,
      token: '',
      filterMenuPosition: undefined,
      filterRequestTypes: [],
      filterRequestType: '',
      filterCreatedByMe: false,
      filterStatus: '',
      serveyTemplates: [],
      surveyId: '',
      deleteTemplteId: '',
      urlSearchParam: '',
      filterQuery: '',
      loadingSurveyTemplates: false,
      queryStr: '',
      filterByMe: false,
      filterByFav: false,
      communities: [],
      requestors: [],
      filterCommunities: [],
      filterRequestors: [],
      filterByStatus: '',
      deleteId: '',
      notFound: '',
      activeSubscribtionHistoryButton: false,
      activeChooseButton: true,
      subscribtionPlan: true,
      subscribtionHistory: false,
      reportingData: "",
      surveyData: {},
      deleteQuestion: false,
      allQuestion: [],
      stackholderProfileList: [],
      stackholderProfilePagination: [],
      modalOpen: "",
      deleteModal: false,
      stakeholderManagerData:[],
      level: 'Corporate',
      stackholderSearchCallId: "",
      stackholderQuery: "",
      allStackholderOptions: [],
      disabled: false,
      profileCompletionCheck: false,
      selfAssessmentCheck: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.filterRef = React.createRef()
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const userData = await getStorageData('user');

    this.setState({level:JSON.parse(userData||'')?.role?.name || 'Corporate'})
    const access = this.props?.navigation?.history?.location?.state?.ability_type
    console.log("access rights =", access);
    if(access == "read"){
      this.setState({disabled:true})
    }
    if (this.props.openCorporateGovernance !== undefined) {
      this.setState({ corporateForm: this.props.openCorporateGovernance });
    }
    // Customizable Area Start
    let authToken = await getStorageData('authToken');

    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    this.getStackholderProfile(this.state.current_page)
    this.getBoardOperations()
    this.getCorporateGovernanceData(this.state.current_page)
    this.getStakeHolderManager('',1)
    this.checkStatus()
    // Customizable Area End
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage)); this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.setState({isLoading:false,loadingBoardOperation:false})
        this.handleErrorBlock(responseJson)
      }
      switch (apiRequestCallId) {
        case this.checkStatusCallId:
          this.setState({profileCompletionCheck: responseJson.user_has_complete_profile, selfAssessmentCheck:responseJson.has_user_completed_self_assesment, loading: false})
          break;
        case this.getStackholderProfileId:
          this.handleStackHolderStates(responseJson)
          break;
        case this.deleteStackholderProfileId:
          toast.success('Stakeholder Profile Form Deleted')
          this.getStackholderProfile(this.state.current_page)
          this.setState({isLoading:false})          
          break;
        case this.createdBoardOperations:
          this.setState({isLoading:false})
          this.getBoardOperations()
          toast.success("Board Operation Created Successfully")
          break;
        case this.updatedBoardOperations:
          this.getBoardOperations();
          this.setState({isLoading:false});
          toast.success("Board Operation Updated Successfully");
          break;
        case this.editCoporateData:
          this.setState({profilePic:[],isLoading:false})
          this.handleCorporateDataResponse()
          toast.success("Corporate Governance Updated Successfully")
          break;
        case this.addCoporateData:
          this.setState({profilePic:[],isLoading:false})
          this.handleCorporateDataResponse()
          toast.success("Corporate Governance Created Successfully")
          break;
        case this.addedStakeHolderProfileForms :
            this.setState({
              isLoading:false,
              KeyStackHolder: [],
              EngagementApproach: [],
              EngagementTools: [],
              FrequencyTimeline: [],
              selectedStakeHolderManager:"",
            });
            this.getStackholderProfile(this.state.current_page)
            this.onCloseModal()
            toast.success("Stakeholder Created Successfully")
          break;
        case this.editedStakeHolderProfileForms:
          this.setState({
                isLoading:false,
                KeyStackHolder: [],
                EngagementApproach: [],
                EngagementTools: [],
                FrequencyTimeline: [],
                selectedStakeHolderManager:"",
              })
              this.getStackholderProfile(this.state.current_page)
              this.onCloseModal()
              toast.success("Stakeholder Updated Successfully")
          break;
          case this.deleteGovernanceProfileId:
            this.getCorporateGovernanceData(this.state.current_page)
            toast.success('Corporate Governance Form Deleted')
            this.setState({isLoading:false})
            break;
          case this.getCorporateGovernanceList:
            this.handleCorporateGovernanceList(responseJson)
            break;
          case this.boardOperations:
            this.setState({loadingBoardOperation:false})
            this.handleBoardOperationList(responseJson)
            break;
          case this.stakeholderManagerList:
            const stackholderOptions = responseJson?.data.map((d: any) => d)
            this.initApproversPagination=responseJson?.data?.meta?.pagination;
            this.setState({stakeholderManagerData : stackholderOptions, allStackholderOptions : stackholderOptions, stackholderPagination: responseJson?.data?.meta?.pagination})
            break;
            case this.state.stackholderPaginateCallId:
                const approversoptions = responseJson?.data.map((d: any) => d.attributes)
                const approversOptions = this.filterUnique(this.state.stakeholderManagerData.concat(approversoptions))
                this.setState({ stakeholderManagerData: approversOptions, stackholderPagination: responseJson?.data?.meta?.pagination })
            break;
            case this.state.stackholderSearchCallId:
                if (!Array.isArray(responseJson?.data))
                    this.setState({
                      stackholderSearchCallId: '',
                      stakeholderManagerData: [],
                      stackholderPagination: undefined
                })
                else {
                    const approversOptions = responseJson?.data.map((d: any) => d)
                    this.setState({
                      stackholderSearchCallId: '',
                      stakeholderManagerData: approversOptions,
                      stackholderPagination: responseJson?.data?.meta?.pagination,
                    })
                }
            break; 
        
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start
  onCloseModal = () => {
    this.setState({ showEngagementApproachOthersField: false, showFrequencyTimelineOthersField: false, editProfile: false, addNewProfile: false, corporateForm: false, showcorporateForm: false, deleteCorporateGovernanace: false });
  }

  handleOnClick = (row:any) =>{
    if(row.cardNo == "5" || row.cardNo == "6"){
      if(this.state.profileCompletionCheck === false){
        toast.warn("Please complete you profile first")
      }else if(this.state.selfAssessmentCheck === false){
        toast.warn("Please complete your self assessment first")
      }else{
        this.props.navigation.navigate(row.File)
      }
    }else{
      this.props.navigation.navigate(row.File)
    }
  }

  handleShowEngagementField = (data:any) => {
      let val=[]
      val=data?.attributes?.engagement_approach
      let filteredValue=val.filter((t:any)=>t!=='Consult' && t!=='Disclose or Replace' && t!=='Involve').toString()
      this.setState({engagementData:filteredValue})
      const index= val.indexOf(filteredValue)
      if (index !== -1) {
      val[index] = 'Others - Please Specify';
      this.setState({EngagementApproach:val})
      this.setState({showEngagementApproachOthersField:true})
      }
  }

  handleCorporateDataResponse = () =>{
    this.getCorporateGovernanceData(this.state.current_page)
    this.onCloseModal()
  }

  handleBoardOperationList = (responseJson:any) => {
    if (!responseJson?.error) {
      if (responseJson?.data !== null) {
        this.setState({ boardOperationData: responseJson?.data, 
          filesArray: responseJson?.data?.attributes?.upload_agenda,
          statutoryAuditors: responseJson?.data?.attributes?.review,
          determinationResolution: responseJson?.data?.attributes?.fees_determination,
          remunirationCommittees: responseJson?.data?.attributes?.upload_reference_of_remuneration_committees,
          referenceOfAuditCommittees: responseJson?.data?.attributes?.upload_reference_of_audit_committees,
          boardMeetingsAttachements: responseJson?.data?.attributes?.upload_minutes_of_last_three_board_meetings,
          agendaOfLastThreeMeeting:responseJson?.data?.attributes?.agenda_of_last_three_board_meetings,
          })
          this.setState({
            agendaOfFirstBoardMeetings:responseJson?.data?.attributes?.agenda_of_last_three_board_meetings?.split(',')[0] || null,
            })
          responseJson?.data?.attributes?.agenda_of_last_three_board_meetings?.split(',').length>=2 && this.setState({
            agendaOfSecondBoardMeetings:responseJson?.data?.attributes?.agenda_of_last_three_board_meetings?.split(',')[1] || null,
            })
          responseJson?.data?.attributes?.agenda_of_last_three_board_meetings?.split(',').length>=3 && this.setState({
           agendaOfThirdBoardMeetings:responseJson?.data?.attributes?.agenda_of_last_three_board_meetings?.split(',')[2] || null
          })
        }
    }
  }

  handleErrorBlock = (responseJson: any) => {
    this.setState({ isLoading: false, loading: false });
    let tokenError = responseJson?.errors?.some((obj:any) => 'token' in obj)
    !tokenError && toast.error("Something went Wrong");
    this.parseApiErrorResponse(responseJson.errors);
    this.parseApiCatchErrorResponse(responseJson.errors);
  }

  filterUnique = (items: any[]) => {
    const hashmap = new Set()
    return items.filter((i: any) => {
      if (hashmap.has(i.id)) return false
      hashmap.add(i.id)
      return hashmap
    })
  }

  handleCorporateGovernanceList = (responseJson: any) => {
    this.getBoardOperations()
    this.setState({
      handleLoading: false,
      corporateGovernanceData: responseJson?.forms?.data,
      governance_current_page: responseJson?.meta?.pagination?.current_page,
      governance_total_pages: responseJson?.meta?.pagination?.total_pages,
      loading: false
    })
  }


  async getStackholderProfile(page: any) {
    const token = await getStorageData('authToken');

    !this.state.handleLoading && this.setState({ loading: true })
    this.getStackholderProfileId = sendAPIRequest(`${configJSON.getStackholderProfile}?page=${page}&per_page=${10}`, {
      method: "GET",
      headers: { 'token': token }
    })
  }
  setFilesArray = (file: any) => {
    this.setState({ filesArray: [file] });
  }
  setBoardMeetingsArray = (file: any) => {
    this.setState({ boardMeetingsAttachements: [file] });
  }
  setAuditCommitteesArray = (file: any) => {
    this.setState({ referenceOfAuditCommittees: [file] });
  }
  setRemunarationCommitteesArray = (file: any) => {
    this.setState({ remunirationCommittees: [file] });
  }
  setNominationCommitteesArray = (file: any) => {
    this.setState({ nominationCommittees: [file] });
  }
  setDeterminationResolutionArray = (file: any) => {
    this.setState({ determinationResolution: [file] });
  }
  setStatutoryAuditorsArray = (file: any) => {
    this.setState({ statutoryAuditors: [file] });
  }


  handleCvFileUpload = (e:any) => {
    this.setState({cv:[e]})
  }

  removeProfilePic = () => {
    this.state.imageUrl=="" ? this.setState({profilePic:[],imageUrl:null}):
    this.setState({profilePic:[],imageUrl:""})
  }
  handleStackHolderStates = (responseJson: any) => {
    this.setState({
      handleLoading: false,
      stackholderProfileList: responseJson?.forms?.data,
      stackholderProfilePagination: responseJson.meta,
      total_count: responseJson?.meta?.pagination?.total_count,
      current_page: responseJson?.meta?.pagination?.current_page,
      total_pages: responseJson?.meta?.pagination?.total_pages,
      loading: false,
    })
    this.setState({ isLoading: false })
  }

  handleDeletePopup = async(data: any) => {
    if(this.state.stackholderProfileList?.length==1 && this.state.current_page!==1){
      this.setState({current_page:this.state.current_page-1,total_pages:this.state.total_pages-1})
    }
    else if(this.state.stackholderProfileList?.length==0){
      this.setState({current_page:1,total_pages:1})
    }
    else{
      this.setState({current_page:this.state.current_page,total_pages:this.state.total_pages})
    }
    const token = await getStorageData('authToken');
    this.setState({delete:false, handleLoading: true,isLoading:true })
    this.deleteStackholderProfileId = sendAPIRequest(`${configJSON.getStackholderProfile}/${data}`, {
      method: "DELETE",
      headers: { 'token': token }
    })
  }

  handleDeleteGovernance = async(data: any) => {
    const token = await getStorageData('authToken');
    this.setState({deleteCorporateGovernanace:false, handleLoading: true,isLoading:true })
    this.deleteGovernanceProfileId = sendAPIRequest(`bx_block_peoplemanagement2/corporate_governances/${data}`, {
      method: "DELETE",
      headers: { 'token': token }
    })
  }

  onCloseCorporateModal = () => {
    this.setState({ profilePic:[],industryExperience:[], corporateForm: false, deleteCorporateGovernanace: false },()=> this.getCorporateGovernanceData(this.state.current_page));
  }





  handleEditStackHolder = (id: any, item: any) => {
    if(this.state?.disabled) {
      toast.warn("You are not able to perform this action")
      return
    }
    this.setState({
      tableRowId: id,
      delete: false,
      KeyStackHolder: item?.attributes?.key_stakeholders.split(','),
      EngagementApproach: item?.attributes?.engagement_approach[0]!=="" ? item?.attributes?.engagement_approach : [],
      EngagementTools: item?.attributes?.engagement_tools.split(','),
      FrequencyTimeline: item?.attributes?.frequency_timeline.split(','),
      selectedStakeHolderManager: item?.attributes?.stakeholder_manager_id,
    }, () => console.log(this.state.KeyStackHolder, "KeyStackHolder"))
    this.setState({ editProfile: !this.state.editProfile })
    this.setState({ addNewProfile: false, corporateForm: false })
  }


  handleEditCorporateGovernance = (row?: any) => {
    if(this.state?.disabled) {
      toast.warn("You are not able to perform this action")
      return;
    }
    this.setState({industryExperience:row?.attributes?.industry_experience.split(','), cv:row?.attributes?.cv.length>0 ? row?.attributes?.cv : [],imageUrl:""})
    this.setState({ tableRowId: row?.id, showcorporateForm: false, isEditGovernance: true, deleteCorporateGovernanace: false, delete: false })
    this.setState({ corporateForm: !this.state.corporateForm })
    this.setState({ editProfile: false })
    this.setState({ addNewProfile: false })
  }

  handleAddCorporateGovernance = () => {
    this.setState({ showcorporateForm: false,industryExperience:[], isEditGovernance: false,cv:[],imageUrl:"",profilePic:[] })
    this.setState({ corporateForm: !this.state.corporateForm })
    this.setState({ editProfile: false })
    this.setState({ addNewProfile: false })
  }

  handleShowCorporateGovernance = (row?: any) => {
    this.setState({cv:row?.attributes?.cv.length>0 ? row?.attributes?.cv : [],imageUrl:""})
    this.setState({ tableRowId: row?.id, showcorporateForm: true, isEditGovernance: true, deleteCorporateGovernanace: false })
    this.setState({ corporateForm: !this.state.corporateForm, industryExperience:row?.attributes?.industry_experience.split(',') })
    this.setState({ editProfile: false })
    this.setState({ addNewProfile: false })
  }

  handleAddNewProfile = (navigation: any) => {
    this.setState({ editProfile: false, corporateForm: false, showcorporateForm: false })
    this.setState({ addNewProfile: !this.state.addNewProfile })
  }





  checkStatus = async() => {
    const authToken = await getStorageData('authToken');

    this.setState({loading: true})
    this.checkStatusCallId = sendAPIRequest(`${configJSON.getStatusEndpoint}`, {
      method: 'GET',
      headers: {
        token: authToken,
        'content-type': 'appliction/json'
      },
    })
  }






  handleAddCorporateGovernanceData = async(values: any) => {
    const authToken = await getStorageData('authToken');

    this.setState({ handleLoading: true,isLoading:true })
    const body = new FormData();
    body.append("relationship_status", values?.relationship_status)
    body.append("age", values?.age);
    body.append("gender", values?.gender);
    body.append("first_name", values?.first_name);
    body.append("last_name", values?.last_name);
    body.append("no_of_years_associated", values?.no_of_years_associated);
    body.append("qualification", values?.qualification);
    body.append("total_experience", values?.total_experience);
    body.append("industry_experience", values?.industry_experience.toString());
    body.append("no_of_other_directorships", values?.no_of_other_directorships);
    body.append("no_of_directorships", values?.no_of_directorships)
    body.append("member_type", values?.member_type);
    body.append("profile_pic", this.state.profilePic[0]);
    body.append("links[]", values?.links);
    body.append("board_committee[]", values?.board_committee);
    body.append("chairman_of_committee[]", values?.chairman_of_committee);
    values?.cv?.length > 0 && values.cv.filter((val: any) =>  body.append("cv[]", val))
    this.addCoporateData = sendAPIRequest(`${configJSON.addCorporateGovernance}`, {
      body: body,
      method: 'POST',
      headers: {
        token: authToken
      },
    })
  }

  handleEditCorporateGovernanceData = async(values: any, id: any) => {
    const authToken = await getStorageData('authToken');

    this.setState({ handleLoading: true,isLoading:true })
    const body = new FormData();
    body.append("relationship_status", values?.relationship_status)
    body.append("age", values?.age);
    body.append("gender", values?.gender);
    body.append("first_name", values?.first_name);
    body.append("last_name", values?.last_name);
    body.append("no_of_years_associated", values?.no_of_years_associated);
    body.append("qualification", values?.qualification);
    body.append("total_experience", values?.total_experience);
    body.append("industry_experience", values?.industry_experience?.toString());
    body.append("no_of_other_directorships", values?.no_of_other_directorships);
    body.append("member_type", values?.member_type);
    body.append("links[]", values?.links);
    body.append("board_committee[]", values?.board_committee);
    body.append("chairman_of_committee[]", values?.chairman_of_committee);
    values?.cv?.length > 0 && values.cv.filter((val: any) => val?.size && !val.url && body.append("cv[]", val))
    
    if(this.state.profilePic.length>0){
      body.append("profile_pic", this.state.profilePic[0]);
    }
      
    this.editCoporateData = sendAPIRequest(`${configJSON.editCorporateGovernanace}/${id}`, {
      body: body,
      method: 'PATCH',
      headers: {
        token: authToken
      },
    })
  }

  createData(
    SlNo: number,
    Policy_Name: string,
    Description: string,
    Attachments: string,
    Created_By: string,
    Last_Updated: string,
  ) {
    return { SlNo, Policy_Name, Description, Attachments, Created_By, Last_Updated };
  }

  createDataMenu(
    Step: string,
    Name: string,
    File: string,
    img: string,
    type: string,
    cardNo: string,
  ) {
    return { Step, Name, File, img, type,cardNo};
  }

  chooseSubscribtionPlan = () => {
    this.setState({ activeSubscribtionHistoryButton: false })
    this.setState({ activeChooseButton: true })
    this.setState({ subscribtionPlan: true });
    this.setState({ subscribtionHistory: false });
  }
  chooseSubscribtionHistory = () => {
    {(this.state.boardOperationData?.id==undefined || this.state.boardOperationData?.id==null || this.state.boardOperationData?.id=="") && this.setState({loadingBoardOperation:true})}
    this.setState({ activeChooseButton: false })
    this.setState({ activeSubscribtionHistoryButton: true })
    this.setState({ subscribtionPlan: false });
    this.setState({ subscribtionHistory: true });
    this.getCorporateGovernanceData(this.state.current_page)
    this.getBoardOperations()
  }

  getCorporateGovernanceData = async(page: any) => {
    const token= await getStorageData('authToken');
    !this.state.handleLoading && this.setState({ loading: true })
    this.getCorporateGovernanceList = sendAPIRequest(`${configJSON.getCorporateGovernanceData}?page=${page}&per_page=${10}`, {
      method: "GET",
      headers: { 'token': token }
    })
  }

  handlePageChange = (event: any, value: any) => {
    this.setState({ current_page: value }, () => this.getStackholderProfile(value));
  }
  handleGovernancePageChange = (event: any, value: any) => {
    this.setState({ governance_current_page: value }, () => this.getCorporateGovernanceData(value));
  }

  handleModal = (value: any) => {
    if(this.state?.disabled) {
      toast.warn("You are not able to perform this action")
      return;
    }
    this.setState({ tableRowId: value?.id, delete: true })
  }
  handleCloseDelete = () => {
    this.setState({ delete: false })
  }
  handleDeleteCorporateGovernance = (value: any) => {
    if(this.state?.disabled) {
      toast.warn("You are not able to perform this action")
      return;
    }
    this.setState({ tableRowId: value?.id, deleteCorporateGovernanace: true })
  }
  handleDeleteGovernanceModal = () => {
    this.setState({ deleteCorporateGovernanace: false })
  }
  editBoardOperationsList = async(values: any) => {
    const authToken= await getStorageData('authToken');

    this.setState({isLoading:true})
    const body = new FormData();
    body.append("id", values?.id)
    this.state.agendaOfFirstBoardMeetings!==null ? body.append("agenda_of_last_three_board_meetings", this.state.agendaOfFirstBoardMeetings.concat(",", this.state.agendaOfSecondBoardMeetings,",", this.state.agendaOfThirdBoardMeetings)) : body.append("agenda_of_last_three_board_meetings", "")
    body.append("minutes_of_last_three_board_meetings", values?.minutes_of_last_three_board_meetings)
    body.append("reference_of_audit_committees", values?.reference_of_audit_committees)
    body.append("head_of_internal_auditor", values?.head_of_internal_auditor)
    body.append("internal_auditor_reporting_to", values?.internal_auditor_reporting_to)
    body.append("partner_engaged_names", values?.partner_engaged_names)
    body.append("statutory_auditor_consulting_company_name", values?.statutory_auditor_consulting_company_name)
    body.append("terms_of_engagement_of_statutory_auditors", values?.terms_of_engagement_of_statutory_auditors)
    body.append("auditor_fees_amount", values?.auditor_fees_amount)
    values?.upload_minutes_of_last_three_board_meetings.length > 0 && values.upload_minutes_of_last_three_board_meetings.filter((val: any) => val?.size && !val.url && body.append("upload_minutes_of_last_three_board_meetings[]", val))

    values?.upload_reference_of_audit_committees.length > 0 && values.upload_reference_of_audit_committees.filter((val: any) => val?.size && !val.url && body.append("upload_reference_of_audit_committees[]", val))

    values?.upload_reference_of_remuneration_committees.length > 0 && values.upload_reference_of_remuneration_committees.filter((val: any) => val?.size && !val.url && body.append("upload_reference_of_remuneration_committees[]", val))

    values?.upload_reference_of_nomination_committees.length > 0 && values.upload_reference_of_nomination_committees.filter((val: any) => val?.size && !val.url && body.append("upload_reference_of_nomination_committees[]", val))

    values?.review.length > 0 && values.review.filter((val: any) => val?.size && !val.url && body.append("review[]", val))

    values?.fees_determination.length > 0 && values.fees_determination.filter((val: any) => val?.size && !val.url && body.append("fees_determination[]", val))

    values?.upload_agenda.length > 0 && values.upload_agenda.filter((val: any) => val?.size && !val.url && body.append("upload_agenda[]", val))

    this.updatedBoardOperations = sendAPIRequest(`${configJSON.editBoardOperations}`, {
      body: body,
      method: 'PATCH',
      headers: {
        token: authToken
      },
    })
    this.deleteUploadedFiles()
  }

  deleteUploadedFiles = async() => {
    const authToken= await getStorageData('authToken');

    if(this.state.removedAttachments?.length>0){
      let attachmentId=this.state.removedAttachments.map((val: any) => val.attachment_id)
      let attachementType=this.state.removedAttachments.map((val: any) => val.attachment_type)

      this.deletedBoardOperations = sendAPIRequest(`${configJSON.deletedBoardOperationData}?id=${this.state.boardOperationData?.id}`, {
        method: 'DELETE',
        body: {
          'ids': attachmentId,
          "attachment_types": attachementType
        },
        headers: {
          token: authToken,
          'Content-Type': 'application/json',
        },
      })
      this.setState({removedAttachments:[]})
    }
  }

  createBoardOperations = async(values: any) => {
    const authToken= await getStorageData('authToken');

    this.setState({isLoading:true})
    const body = new FormData();
    if(this.state.agendaOfFirstBoardMeetings!==null){
      body.append("agenda_of_last_three_board_meetings", this.state.agendaOfFirstBoardMeetings.concat(",", this.state.agendaOfSecondBoardMeetings,",", this.state.agendaOfThirdBoardMeetings))
    }
    else{
      body.append("agenda_of_last_three_board_meetings", "")
    }
    body.append("minutes_of_last_three_board_meetings", values?.minutes_of_last_three_board_meetings)
    body.append("reference_of_audit_committees", values?.reference_of_audit_committees)
    body.append("head_of_internal_auditor", values?.head_of_internal_auditor)
    body.append("internal_auditor_reporting_to", values?.internal_auditor_reporting_to)
    body.append("partner_engaged_names", values?.partner_engaged_names)
    body.append("statutory_auditor_consulting_company_name", values?.statutory_auditor_consulting_company_name)
    body.append("terms_of_engagement_of_statutory_auditors", values?.terms_of_engagement_of_statutory_auditors)
    body.append("auditor_fees_amount", values?.auditor_fees_amount)
    values?.upload_minutes_of_last_three_board_meetings.length > 0 && values?.upload_minutes_of_last_three_board_meetings?.map((item: any) => {
        body.append("upload_minutes_of_last_three_board_meetings[]", item)
    })
    values?.upload_reference_of_audit_committees.length > 0 && values?.upload_reference_of_audit_committees?.map((item: any) => {
        body.append("upload_reference_of_audit_committees[]", item)
    })
    values?.upload_reference_of_remuneration_committees.length > 0 && values?.upload_reference_of_remuneration_committees?.map((item: any) => {
        body.append("upload_reference_of_remuneration_committees[]", item)
    })
    values?.upload_reference_of_nomination_committees.length > 0 && values?.upload_reference_of_nomination_committees?.map((item: any) => {
        body.append("upload_reference_of_nomination_committees[]", item)
    })
    values?.review.length > 0 && values?.review?.map((item: any) => {
        body.append("review[]", item)
    })
    values?.fees_determination.length > 0 && values?.fees_determination?.map((item: any) => {
        body.append("fees_determination[]", item)
    })
    values?.upload_agenda.length > 0 && values?.upload_agenda?.map((item: any) => {
        body.append("upload_agenda[]", item)
    })
    this.createdBoardOperations = sendAPIRequest(`${configJSON.createBoradOperation}`, {
      body: body,
      method: 'POST',
      headers: {
        token: authToken
      },
    })
  }

  getBoardOperations = async() => {
    const authToken= await getStorageData('authToken');

    this.boardOperations = sendAPIRequest(`${configJSON.getBoardOperationsData}`, {
      method: 'GET',
      headers: {
        token: authToken
      },
    })
  }

  deleteBoardOperationFiles = (id: any, attachment_id: any, attachment_type: any) => {
    if(attachment_id){
      this.setState((prevState:any) => ({
          removedAttachments: [...prevState.removedAttachments, {id: id,attachment_id:attachment_id, attachment_type:attachment_type}]
      }))
  }
  }

  deleteCVDocumentField = async(id: any, attachment_id: any) => {
    const authToken= await getStorageData('authToken');

    this.deletedCV = sendAPIRequest(`${configJSON.deleteCvDocument}?id=${id}&attachment_id=${attachment_id}&attachment_type=cv`, {
      method: 'DELETE',
      headers: {
        token: authToken
      },
    })
  }

  setProfilePic = (file: any) => {
    this.setState({ profilePic: [file.currentTarget.files[0]],imageUrl:URL.createObjectURL(file.currentTarget.files[0]) },()=>console.log(this.state.imageUrl,"ppppppppp"));
  }

  onCloseStackHolderModal = () => {
    this.setState({ addNewProfile: false, editProfile: false, showEngagementApproachOthersField: false, showFrequencyTimelineOthersField: false,EngagementApproach:[],EngagementTools:[],FrequencyTimeline:[],KeyStackHolder:[],selectedStakeHolderManager:""},()=>console.log(this.state.selectedStakeHolderManager,"test"));
    this.getStackholderProfile(this.state.current_page)
  }

  handleKeyStackHolder = (values: any) => {
    this.setState({ KeyStackHolder: values })
  }

  handleStackHolderManager = (values:any) => {
    this.setState({selectedStakeHolderManager:values?.attributes?.id})
  }

  handleChangeIndustryValues = (chip:any,setFieldValue:any) => {
    this.setState({
      industryExperience: chip.target.value //shorthand way to copy an array and add a new element
    },()=>{setFieldValue("industry_experience",this.state.industryExperience)});
  };

  handleDeleteIndustry = (chipToDelete: any,setFieldValue:any) => () => {
    this.setState({
      industryExperience: this.state.industryExperience.filter(c => c !== chipToDelete) //filter will produce a copy of the array
    },()=>{setFieldValue("industry_experience",this.state.industryExperience)});
  };

  handleEngagementApproach = (values: any) => {
    if(values[0]=='Others - Please Specify' && values.length>1){
      this.setState({engagementData:""})
      this.setState({showEngagementApproachOthersField: false })
      this.setState({ EngagementApproach: values.slice(1)})
    }
    else if(values[0]=='Others - Please Specify' && values.length==1){
      this.setState({engagementData:""})
      this.setState({ showEngagementApproachOthersField: true })
      this.setState({ EngagementApproach: values})
    }
    else if (values.includes('Others - Please Specify') && values[0]!=='Others - Please Specify') {
      this.setState({engagementData:""})
      this.setState({ showEngagementApproachOthersField: true })
      const data= values.filter((val:any)=>val==='Others - Please Specify')
      this.setState({ EngagementApproach: data})
    }
    else if(!values.includes('Others - Please Specify')){
      this.setState({ showEngagementApproachOthersField: false })
      this.setState({engagementData:""})
      this.setState({ EngagementApproach: values})
    }
  }

  handleEnagegementTools = (values: any) => {
    this.setState({ EngagementTools: values })
  }

  handleFrequencyTimeline = (values: any) => {
    if (values==='Others - Please Specify') {
      this.setState({ showFrequencyTimelineOthersField: true })
      this.setState({ FrequencyTimeline: values })
    }
    else if(values!==null) {
      this.setState({ showFrequencyTimelineOthersField: false })
      this.setState({ FrequencyTimeline: values })
    }
    else{
      this.setState({ FrequencyTimeline: ['']})
    }
  }

  onCloseGovernanceModal = () => {

  }
  
  handleAddStakeHolderStateData = (index:any,values:any)=> {
    if (index !== -1) {
      this.state.EngagementApproach[index] = values?.other_please_specify;
    }
  }

  createFormSchema = yup.object({
    first_name: yup.string().label('First Name').required().matches(/^[a-zA-Z\s]+$/,'Only alpbhabets are accepted').min(0).max(100),
    last_name: yup.string().label('Last Name').required().matches(/^[a-zA-Z\s]+$/,'Only alpbhabets are accepted').min(0).max(100),
    gender:yup.string().label('Gender').required(),
    relationship_status:yup.string().label('Relationship Status').required(),
    age:yup.number().label("Age").typeError('Must be a number').required(),
    total_experience:yup.number().label('Total Experience').typeError('Must be a number').required(),
    no_of_years_associated:yup.number().label('No. Of Years Associated').typeError('Must be a number').required(),
    qualification:yup.string().label('Qualification').required(),
    no_of_other_directorships:yup.number().label('No. Of Other Directorships').typeError('Must be a number').required(),
    member_type:yup.string().label('Member Type').required(),
    board_committee:yup.string().label('Board Committee').required(),
    chairman_of_committee:yup.string().label('Chairman Of Committee').required(),
    no_of_directorships:yup.number().label('No. Of Directorships').typeError('Must be a number').required(),
  })

  createValidationSchema = yup.object({
    auditor_fees_amount:yup.number().label('Auditor Fees amount').typeError('Must be a number'),
   })

  addStakeHolderProfileForms = (values: any) => {
    this.setState({ handleLoading: true,isLoading:true })
    const body = new FormData();
    let index = this.state.EngagementApproach.indexOf('Others - Please Specify');
    body.append("key_stakeholders", this.state.KeyStackHolder?.length > 0 ? this.state.KeyStackHolder?.toString() : '')
    if (values?.other_please_specify !== '') {
      this.handleAddStakeHolderStateData(index,values)
      this.state.EngagementApproach?.length>0 ? this.state.EngagementApproach?.filter((val: any) => body.append("engagement_approach[]", val )) : body.append("engagement_approach[]", this.state.EngagementApproach )
    }
    else {
      this.state.EngagementApproach?.length>0 ? this.state.EngagementApproach?.filter((val: any) => body.append("engagement_approach[]", val )) : body.append("engagement_approach[]",this.state.EngagementApproach )
    }
    body.append("engagement_tools", this.state.EngagementTools?.length > 0 ? this.state.EngagementTools.toString() : '');
    if (values?.frequency_timeline_other_please_specify !== '') {
      body.append("frequency_timeline", this.state.FrequencyTimeline?.length > 0 ? `${values?.frequency_timeline_other_please_specify}` : '');
    }
    else {
      body.append("frequency_timeline", this.state.FrequencyTimeline?.length > 0 ? this.state.FrequencyTimeline?.toString() : '');
    }
    values?.stakeholder_manager?.filter((val: any) => body.append("stakeholder_manager[]", val ))
    this.handleRequestForAddStakeHolder(body)
  }

  dateChangeOfFirstMeeting = (v:any) => {
    if (v) {
      this.setState({agendaOfFirstBoardMeetings: v.toString() },()=>console.log(this.state.agendaOfFirstBoardMeetings,"console"))
    } else {
    this.setState({agendaOfFirstBoardMeetings:null})
    }
  }
  dateChangeOfSecondMeeting = (v:any) => {
    if (v) {
    this.setState({agendaOfSecondBoardMeetings:  v.toString() })
    }
    else {
      this.setState({agendaOfSecondBoardMeetings:null})
    }
  }
  dateChangeOfThirdMeeting = (v:any) => {
    if (v) {
    this.setState({agendaOfThirdBoardMeetings: v.toString() })
    }
    else {
      this.setState({agendaOfThirdBoardMeetings: null})
    }
  }

  handleRequestForAddStakeHolder = async(body:any) => {
    const authToken= await getStorageData('authToken');

    this.addedStakeHolderProfileForms = sendAPIRequest(`${configJSON.stakeHolderProfileFOrms}`, {
      body: body,
      method: 'POST',
      headers: {
        token: authToken
      },
    })
  }

  frequencyTimeline = (values:any,body:any) => {
    if (values?.frequency_timeline_other_please_specify !== '') {
      body.append("frequency_timeline", this.state.FrequencyTimeline?.length > 0 ? `${values?.frequency_timeline_other_please_specify}` : '');
    }
    else {
      body.append("frequency_timeline", this.state.FrequencyTimeline?.length > 0 ? this.state.FrequencyTimeline?.toString() : '');
    }
  }

  editStakeHolderProfileForms = async(values: any, data: any) => {
    const authToken= await getStorageData('authToken');

    this.setState({ handleLoading: true,isLoading:true })
    const body = new FormData();
    body.append("key_stakeholders", this.state.KeyStackHolder?.length > 0 ? this.state.KeyStackHolder?.toString() : '')
    if (values?.other_please_specify !== '') {
      let index = this.state.EngagementApproach.indexOf('Others - Please Specify');
      if (index !== -1) {
        this.state.EngagementApproach[index] = values?.other_please_specify;
      }
      this.state.EngagementApproach?.length>0 ? this.state.EngagementApproach?.filter((val: any) => body.append("engagement_approach[]", val )) : body.append("engagement_approach[]", this.state.EngagementApproach )
    }
    else {
      this.state.EngagementApproach?.length>0 ? this.state.EngagementApproach?.filter((val: any) => body.append("engagement_approach[]", val )) : body.append("engagement_approach[]", this.state.EngagementApproach )
    }
    body.append("engagement_tools", this.state.EngagementTools?.length > 0 ? this.state.EngagementTools.toString() : '');
    this.frequencyTimeline(values,body)
    values?.stakeholder_manager?.filter((val: any) => body.append("stakeholder_manager[]", val ))

    this.editedStakeHolderProfileForms = sendAPIRequest(`${configJSON.editStakeHolder}/${data?.id}`, {
      body: body,
      method: 'PATCH',
      headers: {
        token: authToken
      },
    })
  }

  getStakeHolderManager = async(search:any,page:any) => {
    const authToken= await getStorageData('authToken');

    this.stakeholderManagerList = sendAPIRequest(`${configJSON.getStakeHolderManager}?search=${search}&page=${page}`, {
      method: 'GET',
      headers: {
        token: authToken
      },
    })
  }

  handleApproversClose = () => {
    this.setState({
      stackholderSearchCallId: '',
      stackholderQuery: '',
      stackholderPaginateCallId: '',
      stakeholderManagerData: this.state.allStackholderOptions,
      stackholderPagination: this.initApproversPagination,
    })
  }

  handleStackholderSearch = async(query: string, page?: number) => {
    const token= await getStorageData('authToken');
    const params = new URLSearchParams()
    query && params.append('search', query.toLowerCase())
    page && params.append('page', String(page))
    const url =configJSON.getStakeHolderManager + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }


  handleStackholderSearchQuery = async(query: string) =>{
    let stackholderSearchCallIdData = await this.handleStackholderSearch(query)
    if (query && query!=="" )
      this.setState({
        stackholderSearchCallId: stackholderSearchCallIdData,
        stackholderQuery: query,
        stackholderPaginateCallId: ''
      })
    else
      this.setState({
        stakeholderManagerData: this.state.allStackholderOptions,
        stackholderPagination: this.initApproversPagination,
        stackholderQuery: '',
        stackholderSearchCallId: '',
      })
  }

  paginateStackholder = () => {
    if (this.state.stackholderSearchCallId !== '') return
    const { next_page } = this.state.stackholderPagination
    this.setState({ stackholderPaginateCallId: this.handleStackholderSearch(this.state.stackholderQuery, next_page) })
  }
  // Customizable Area End

}
